import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import _ from "lodash";
import React from "react";
import { PropagateLoader } from "react-spinners";
import { GetPurchaseSummeryReport } from "../../../axios";

const PoSummery = (props) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
      flex: 1,
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "90px",
      height: "30px",
    },
    section: {
      margin: 10,
      padding: 10,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-500px",
      fontSize: "8px",
    },
  });

  const { id } = props;
  const [shows, setShows] = React.useState(false);
  const [reportData, setReportData] = React.useState([]);
  const [purchaseData, setPurchaseData] = React.useState([]);

  React.useEffect(() => {
    GetPurchaseSummeryReport(id)
      .then((res) => {
        setReportData(res.data.data);

        const data = res.data.data.summaryData.map((ele) => {
          var group = _.groupBy(ele.purchase_order, "package_reference_number");
          var result = _.map(_.keys(group), function (e) {
            return _.reduce(
              group[e],
              function (r, o) {
                return (r.count += +o.total_price), r;
              },
              {
                Company: e,
                sum: group[e],
                count: 0,
              }
            );
          });
          return { ...ele, purchase_order: result };
        });
        setPurchaseData(data);

        setShows(true);
      })
      .catch((err) => {
        setShows(false);
      });
  }, []);

  return (
    <>
      {shows === true ? (
        // <PDFViewer style={styles.viewer}>
          <Document>
            <Page size="A4" style={styles.page} wrap>
              <View fixed>
                <View style={styles.section} fixed>
                  {/* <Image style={styles.image} src={logoRect} /> */}
                  <Text style={{ fontSize: "12px" }}>
                    Purchase Orders Summary Report
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: "25px",
                  marginRight: "25px",
                  marginTop: "30px",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    border: "1px solid black",
                    marginTop: "10px",
                    borderRadius: "5px",
                  }}
                  fixed
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "13px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        width: "39%",
                        fontSize: "11px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Project Name
                    </Text>
                    <Text
                      style={{ width: "61%", fontSize: "11px", padding: "5px" }}
                    >
                      {reportData.budgetprojectData[0]?.project.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "39%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Budget Number
                    </Text>
                    <Text
                      style={{ width: "61%", fontSize: "10px", padding: "5px" }}
                    >
                      {reportData.budgetprojectData[0]?.serial_number +
                        " - " +
                        reportData.budgetprojectData[0]?.project.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Text
                      style={{
                        width: "39%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Issuing Date
                    </Text>
                    <Text
                      style={{ width: "61%", fontSize: "10px", padding: "5px" }}
                    >
                      {
                        reportData.budgetprojectData[0]?.budget_start_date.split(
                          " "
                        )[0]
                      }
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        width: "39%",
                        fontSize: "10px",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "#f2f2f2",
                      }}
                    >
                      Total Ordered Value
                    </Text>
                    <Text
                      style={{ width: "61%", fontSize: "10px", padding: "5px" }}
                    >
                      AED {reportData.Ordered_price?.total_price}
                    </Text>
                  </View>
                </View>

                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        fontSize: "10px",
                        backgroundColor: "#f2f2f2",
                      }}
                      fixed
                    >
                      <View
                        style={{
                          width: "20%",
                          height: "100%",
                          borderRight: "1px solid black",
                        }}
                      >
                        <Text style={{ margin: "auto" }}> </Text>
                      </View>
                      <View
                        style={{
                          width: "15%",
                          height: "100%",
                          borderRight: "1px solid black",
                        }}
                      >
                        <Text style={{ margin: "auto" }}>Status</Text>
                      </View>
                      <View
                        style={{
                          width: "15%",
                          height: "100%",
                          borderRight: "1px solid black",
                        }}
                      >
                        <Text style={{ margin: "auto" }}>Updated on</Text>
                      </View>
                      <View
                        style={{
                          width: "15%",
                          height: "100%",
                          borderRight: "1px solid black",
                        }}
                      >
                        <Text style={{ margin: "auto" }}>
                          Submitted To Client
                        </Text>
                      </View>
                      <View
                        style={{
                          width: "15%",
                          height: "100%",
                          borderRight: "1px solid black",
                        }}
                      >
                        <Text style={{ margin: "auto" }}>Signed By Client</Text>
                      </View>
                      <View style={{ width: "20%", height: "100%" }}>
                        <Text style={{ margin: "auto" }}>Total</Text>
                      </View>
                    </View>
                    {purchaseData &&
                      purchaseData.map((ele, index) => {
                        return (
                          <>
                            <View
                              key={index}
                              style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                padding: "5px 3px",
                                borderTop: "1px solid black",
                                backgroundColor: "#347380",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontSize: "9px",
                                  width: "100%",
                                }}
                              >
                                <Text>{ele.name}</Text>
                              </View>
                            </View>
                            {ele.purchase_order.map((elem) => {
                              return (
                                <View
                                  style={{
                                    fontSize: "9px",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    borderTop: "1px solid black",
                                    marginTop: "0px",
                                    padding: "0px 3px",
                                  }}
                                >
                                  <View
                                    style={{
                                      width: "20%",
                                      height: "100%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <Text
                                      style={{ margin: "auto", padding: "5px" }}
                                    >
                                      {elem.sum[0].purchase_order_serial_number}{" "}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      height: "100%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      {elem.sum[0].purchase_order_status}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      height: "100%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      {elem.sum[0].created_on.split(" ")[0]}
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      height: "100%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      {
                                        elem.sum[0].send_to_clint_on?.split(
                                          " "
                                        )[0]
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{
                                      width: "15%",
                                      height: "100%",
                                      borderRight: "1px solid black",
                                    }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      {
                                        elem.sum[0].signed_by_clint_on?.split(
                                          " "
                                        )[0]
                                      }
                                    </Text>
                                  </View>
                                  <View
                                    style={{ width: "20%", height: "100%" }}
                                  >
                                    <Text style={{ margin: "auto" }}>
                                      AED {elem.count?.toFixed(2)}
                                    </Text>
                                  </View>
                                </View>
                              );
                            })}
                            <View
                              key={index}
                              style={{
                                flexDirection: "row",
                                flexWrap: "wrap",
                                borderTop: "1px solid black",
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontSize: "9px",
                                  width: "80%",
                                }}
                              >
                                <Text
                                  style={{ marginLeft: "26px", padding: "3px" }}
                                >
                                  Total
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontSize: "9px",
                                  width: "20%",
                                }}
                              >
                                <Text style={{ margin: "auto" }}>
                                  AED{" "}
                                  {ele.purchase_order
                                    .reduce((acc, curr) => {
                                      return acc + curr.count;
                                    }, 0)
                                    .toFixed(2)}
                                </Text>
                              </View>
                            </View>
                          </>
                        );
                      })}
                  </View>

                  <View>
                    <Text
                      style={{
                        marginTop: "10px",
                        fontSize: "8px",
                        marginLeft: "25px",
                        marginRight: "25px",
                      }}
                    >
                      Checked By Furnish
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        border: "1px solid black",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            borderRight: "1px solid black",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          Name
                        </Text>
                        <Text
                          style={{
                            width: "40%",
                            fontSize: "10px",
                            padding: "5px",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Signature
                        </Text>
                        <Text
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          Date
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: "10px",
                          textAlign: "center",
                        }}
                      >
                        <Text
                          style={{
                            width: "30%",
                            height: "50px",
                            fontSize: "10px",
                            borderRight: "1px solid black",
                            padding: "5px",
                          }}
                        ></Text>
                        <Text
                          style={{
                            width: "40%",
                            height: "50px",
                            fontSize: "10px",
                            padding: "5px",
                            borderRight: "1px solid black",
                          }}
                        ></Text>
                        <Text
                          style={{
                            width: "30%",
                            height: "50px",
                            fontSize: "10px",
                            padding: "5px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        marginTop: "5px",
                        fontSize: "8px",
                        marginLeft: "25px",
                        marginRight: "25px",
                      }}
                    >
                      Authorized By Furnish
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        border: "1px solid black",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            borderRight: "1px solid black",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          Name
                        </Text>
                        <Text
                          style={{
                            width: "40%",
                            fontSize: "10px",
                            padding: "5px",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Signature
                        </Text>
                        <Text
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          Date
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: "10px",
                          textAlign: "center",
                        }}
                      >
                        <Text
                          style={{
                            width: "30%",
                            height: "50px",
                            fontSize: "10px",
                            borderRight: "1px solid black",
                            padding: "5px",
                          }}
                        ></Text>
                        <Text
                          style={{
                            width: "40%",
                            height: "50px",
                            fontSize: "10px",
                            padding: "5px",
                            borderRight: "1px solid black",
                          }}
                        ></Text>
                        <Text
                          style={{
                            width: "30%",
                            height: "50px",
                            fontSize: "10px",
                            padding: "5px",
                          }}
                        ></Text>
                      </View>
                    </View>
                    <Text
                      style={{
                        marginTop: "5px",
                        fontSize: "8px",
                        marginLeft: "25px",
                        marginRight: "25px",
                      }}
                    >
                      Received By Client
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        border: "1px solid black",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: "10px",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <Text
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            borderRight: "1px solid black",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          Name
                        </Text>
                        <Text
                          style={{
                            width: "40%",
                            fontSize: "10px",
                            padding: "5px",
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          Signature
                        </Text>
                        <Text
                          style={{
                            width: "30%",
                            fontSize: "10px",
                            padding: "5px",
                            textAlign: "center",
                          }}
                        >
                          Date
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: "10px",
                          textAlign: "center",
                        }}
                      >
                        <Text
                          style={{
                            width: "30%",
                            height: "50px",
                            fontSize: "10px",
                            borderRight: "1px solid black",
                            padding: "5px",
                          }}
                        ></Text>
                        <Text
                          style={{
                            width: "40%",
                            height: "50px",
                            fontSize: "10px",
                            padding: "5px",
                            borderRight: "1px solid black",
                          }}
                        ></Text>
                        <Text
                          style={{
                            width: "30%",
                            height: "50px",
                            fontSize: "10px",
                            padding: "5px",
                          }}
                        ></Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.footer} fixed>
                <View
                  style={{ borderTop: "1px solid black", marginRight: "50px" }}
                ></View>
                <View>
                  <Text style={{ fontSize: "8px", marginTop: "5px" }}>
                    PO BOX 282247, Dubai, UAE
                  </Text>
                  {/* <Text style={{ fontSize: "8px" }}>tel +971 4 3307725</Text>
                    <Text style={{ fontSize: "8px" }}>
                      <Link
                        style={{ color: "black" }}
                        src="mailto:invitation@furnish-marketplace.com"
                      >
                        invitation@furnish-marketplace.com
                      </Link>
                    </Text>
                    <Text style={{ fontSize: "8px" }}>
                      <Link
                        style={{ color: "black" }}
                        src="http://furnish-marketplace.com"
                      >
                        furnish-marketplace.com
                      </Link>
                    </Text> */}
                </View>
                <View>
                  <Text
                    style={styles.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page # ${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </View>
              </View>
            </Page>
          </Document>
        // </PDFViewer>
      ) : (
        <View style={{ paddingLeft: "15Px", marginLeft: "50%" }}>
          <PropagateLoader color="blue" />
        </View>
      )}
    </>
  );
};

export default PoSummery;
