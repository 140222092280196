/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import * as XLSX from "xlsx";
import { Button } from "../../../components";
import { colors } from "../../../configs";
import Strings from "../../../translations";

const BudgetItemFileUpload = (props) => {
  const {
    errorMessage,
    handleChangeData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    budget_Name,
    budget_id,
  } = props;
  const cancelButton = Strings["Country.CreateCountryCancelButton"],
    saveButton = Strings["Country.CreateCountrySaveButton"],
    updateBtn = Strings["Country.CreateCountryUpdateButton"];

  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.selectedFile ? initialValue.selectedFile : "",
    error: false,
    success: false,
  });
  const [uploadFile, setUploadFile] = React.useState([]);
  const [emptyFileAlert, setEmptyFileAlert] = React.useState(false);
  const [uploadFlag, setFlag] = React.useState(false);

  useEffect(() => {
    if (uploadFlag) {
      if (uploadFile.length == 0) {
        setEmptyFileAlert(true);
      } else {
        setEmptyFileAlert(false);
      }
    }
  }, [emptyFileAlert, uploadFile]);

  const handleChangeFile = (e) => {
    setSelectedFile(e.target.value);
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setUploadFile(json);
        setFlag(true);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleSaveData = () => {
    let itemList = uploadFile;

    const data = itemList.map((item) => {
      return {
        control_number: item["Control Number"],
        budget_name: budget_Name,
        budget_id: budget_id,
        item_group_name: item.Group,
        item_subgroup_name: item["Sub Group"],
        item_category_name: item["Category"],
        description: item["Short Description"],
        name: item["Item Name"],
        budget_quantity: item["Budget Qty"],
        budget_uom: item["Budget UOM"],
        budget_currency: item["Budget Currency"],
        budget_price: item["Budget Unit Price"]
          ? item["Budget Unit Price"]
          : null,
        qtyByRoom: null,
        perLevel: null,
        rooms: null,
        qty: 0,
        tags: item["Tags"],
        total_units: null,
        brand: null,
        totalunits: null,
        actual_uom: item["Actual UOM"] ? item["Actual UOM"] : null,
        actual_quantity: item["Actual Qty"] ? item["Actual Qty"] : null,
        actual_currency: item["Actual Currency"]
          ? item["Actual Currency"]
          : null,
        actual_price: item["Actual Unit Price"]
          ? item["Actual Unit Price"]
          : null,
        ship_to_vendor: item["Ship To Vendor"] ? item["Ship To Vendor"] : null,
        specified_vendor: item["Specified Vendor"]
          ? item["Specified Vendor"]
          : null,
        vendor: item["Vendor"] ? item["Vendor"] : null,
        model_number: item["Model Number"] ? item["Model Number"] : null,
        notes: null,
        size: null,
      };
    });
    const selectedFile = { data: JSON.parse(JSON.stringify(data)) };
    handleChangeData(selectedFile);
  };
  return (
    <div className="modalFormContainer">
      <h2 className="mb-3">{"Upload CSV File"}</h2>
      {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
      {emptyFileAlert && (
        <h6 style={{ color: "red" }}>No Records Found To Upload</h6>
      )}
      <input
        type="file"
        value={selectedFile.value}
        onChange={handleChangeFile}
        accept=".csv, .xlsx"
        style={{
          marginTop: "1.5rem",
        }}
      />
      <div
        style={{
          display: "flex",
          marginTop: "1.5rem",
          justifyContent: "space-between",
        }}
      >
        <Button
          buttonName={cancelButton}
          buttonStyle={{ backgroundColor: colors.darkgrey }}
          onClick={() => {
            handleModalClose();
          }}
        />
        <Button
          buttonName={actionType === "add" ? saveButton : updateBtn}
          buttonStyle={{ backgroundColor: colors.primary }}
          disable={uploadFile.length == 0 ? true : false}
          onClick={handleSaveData}
        />
      </div>
    </div>
  );
};

export default BudgetItemFileUpload;
