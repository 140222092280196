import React,{useImperativeHandle,forwardRef} from "react";
import swal from "sweetalert";
import {
  AllActiveStatusCurrency,
  AllActiveStatusRequiredSubmittals,
  CreateDraftItems,
  CreateVendor,
  GetAllActiveUOM,
  GetBudgetCategory,
  GetBudgetSubGroupByGroup,
  GetItemGroupByScopeId,
  ImageCreation,
  getAllActiveVendor,
} from "../../../axios";
import {
  AddIcon,
  Button,
  FormCheckBox,
  FormDropDown,
  FormTextInput,
  IconButton,
  Modal,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import { colors } from "../../../configs";
import strings from "../../../translations";
import { VendorForm } from "../../Masters";

const BudgetItemForm = forwardRef((props,ref) => {
  const {
    budget_id,
    errorMessage,
    handleBudgetItemData = () => {},
    initialValue = {},
    actionType,
    handleModalClose,
    setMessage,
    draftStatusFunction,
    getDraftItems,
    budget_scope,
    budgetDetail,
  } = props;
  console.log("INIT", initialValue);
  // const stage = initialValue.stage?.name;
  // const [stage, setStage] = React.useState({
  //   value: initialValue.stage
  //     ? initialValue.stage?.id
  //       ? initialValue.stage?.id
  //       : ""
  //     : "",
  //   error: false,
  //   success: false,
  // });
  const [loading, setLoading] = React.useState(false);
  const [itemSerialNo, setItemSerialNo] = React.useState({
    value: initialValue.control_number ? initialValue.control_number : null,
    error: false,
    success: false,
  });
  const [selectedFile, setSelectedFile] = React.useState({
    value: initialValue.files ? initialValue.files[0]?.file_path : null,
    error: false,
    success: false,
  });
  const [itemName, setItemName] = React.useState({
    value: initialValue.name
      ? initialValue.name
        ? initialValue.name
        : ""
      : "",
    error: false,
    success: false,
  });
  const [itemDescription, setItemDescription] = React.useState({
    value: initialValue.description
      ? initialValue.description
        ? initialValue.description
        : ""
      : "",
    error: false,
    success: false,
  });
  const [itemGroup, setItemGroup] = React.useState({
    value: initialValue.itemgroup
      ? initialValue.itemgroup.id
        ? initialValue.itemgroup.id
        : ""
      : "",
    error: false,
    success: false,
  });
  const [itemSubGroup, setItemSubGroup] = React.useState({
    value: initialValue.itemsubgroup
      ? initialValue.item_subgroup_id
        ? initialValue.item_subgroup_id
        : ""
      : "",
    error: false,
    success: false,
  });
  const [itemCategory, setItemCategory] = React.useState({
    value: initialValue.itemcategory
      ? initialValue.itemcategory.id
        ? initialValue.itemcategory.id
        : ""
      : "",
    error: false,
    success: false,
  });
  const [itemBrand, setItemBrand] = React.useState({
    value: initialValue.brand
      ? initialValue.brand
        ? initialValue.brand
        : ""
      : "",
    error: false,
    success: false,
  });
  const [tags, setTags] = React.useState({
    value: initialValue.tags
      ? initialValue.tags
        ? initialValue.tags
        : ""
      : "",
    error: false,
    success: false,
  });
  const [budgetUOM, setBudgetUOM] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.budgetuom
        ? initialValue.budgetquantitydetails.budgetuom.id
        : ""
      : "",
    error: false,
    success: false,
  });
  const [budgetQuantity, setBudgetQuantity] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.budget_quantity
        ? initialValue.budgetquantitydetails.budget_quantity
        : "0"
      : "0",
    error: false,
    success: false,
  });
  const [budgetCurrency, setBudgetCurrency] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.budget_currency_id
        ? initialValue.budgetquantitydetails.budget_currency_id
        : ""
      : "",
    error: false,
    success: false,
  });
  const [budgetPrice, setBudgetPrice] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.budget_price
        ? initialValue.budgetquantitydetails.budget_price
        : ""
      : "",
    error: false,
    success: false,
  });
  const [actualUOM, setActualUOM] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.actualuom
        ? initialValue.budgetquantitydetails.actualuom.id
        : ""
      : "",
    error: false,
    success: false,
  });
  const [actualQuantity, setActualQuantity] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.actual_quantity
        ? initialValue.budgetquantitydetails.actual_quantity
        : ""
      : "",
    error: false,
    success: false,
  });
  const [actualCurrency, setActualCurrency] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.actual_currency_id
        ? initialValue.budgetquantitydetails.actual_currency_id
        : ""
      : "",
    error: false,
    success: false,
  });
  const [actualPrice, setActualPrice] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.actual_price
        ? initialValue.budgetquantitydetails.actual_price
        : ""
      : "",
    error: false,
    success: false,
  });
  const [totalActualPrice, setTotalActualPrice] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.actual_price
        ? initialValue.budgetquantitydetails.actual_price *
          initialValue.budgetquantitydetails.actual_quantity
        : ""
      : "",
    error: false,
    success: false,
  });
  const [totalBudgetPrice, setTotalBudgetPrice] = React.useState({
    value: initialValue.budgetquantitydetails
      ? initialValue.budgetquantitydetails.budget_price
        ? initialValue.budgetquantitydetails.budget_price *
          initialValue.budgetquantitydetails.budget_quantity
        : ""
      : "",
    error: false,
    success: false,
  });
  // var Vendor_List = [];
  // if (initialValue.budget_vendordetails) {
  //   initialValue.budget_vendordetails.vendor.map((ele) => {
  //     Vendor_List.push({
  //       key: ele.id,
  //       label: ele.name,
  //       value: ele.id,
  //     });
  //   });
  // }

  const [specifiedVendor, setSpecifiedVendor] = React.useState({
    value: initialValue
      ? initialValue.budget_specified_vendordetails?.specified_vendor[0]?.id
      : "",
    error: false,
    success: false,
  });
  const [vendor, setVendor] = React.useState({
    value: initialValue
      ? initialValue.budget_vendordetails?.vendordata?.id
      : "",
    error: false,
    success: false,
  });
  const [shiptoVendor, setShiptoVendor] = React.useState({
    value: initialValue
      ? initialValue.budget_ship_to_vendordetails?.ship_to_vendor?.id
      : "",
    error: false,
    success: false,
  });

  const [modelNumber, setModelNumber] = React.useState({
    value: initialValue.budget_modeldetails
      ? initialValue.budget_modeldetails.model_number
        ? initialValue.budget_modeldetails.model_number
        : ""
      : "",
    error: false,
    success: false,
  });
  const [brandName, setBrandName] = React.useState({
    value: initialValue.budget_modeldetails
      ? initialValue.budget_modeldetails.brand
        ? initialValue.budget_modeldetails.brand
        : ""
      : "",
    error: false,
    success: false,
  });
  const [size, setSize] = React.useState({
    value: initialValue.budget_modeldetails
      ? initialValue.budget_modeldetails.size
        ? initialValue.budget_modeldetails.size
        : ""
      : "",
    error: false,
    success: false,
  });
  const [notes, setNotes] = React.useState({
    value: initialValue.budget_modeldetails
      ? initialValue.budget_modeldetails.notes
        ? initialValue.budget_modeldetails.notes
        : ""
      : "",
    error: false,
    success: false,
  });

  const [ItemGroupDropDown, SetItemGroupDropDown] = React.useState([]);
  // const [stageDropDown, setStageDropDown] = React.useState([]);
  const [ItemCategoryDropDown, SetItemCategoryDropDown] = React.useState([]);
  const [UomDropDown, SetUomDropDown] = React.useState([]);
  const [currencyDropDown, SetcurrencyDropDown] = React.useState([]);
  const [vendorDropDown, SetvendorDropDown] = React.useState([]);
  const [specifiedVendorDropDown, setSpecifiedvendorDropDown] = React.useState(
    []
  );
  const [shipVendorDropDown, setshipvendorDropDown] = React.useState([]);
  const [RequiredSubmittalsDropDown, SetRequiredSubmittalsDropDown] =
    React.useState([]);
  const [itemSubGroupOption, setitemSubGroupOption] = React.useState([]);

  const getAllActiveItemGroup = () => {
    setLoading(true);
    GetItemGroupByScopeId(budget_scope)
      .then((res) => {
        console.log(res);
        let data = res.data.data.item_group;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.group_name,
        }));
        setLoading(false);
        SetItemGroupDropDown(result);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  // const getAllActiveStage = () => {
  //   setLoading(true);
  //   GetAllActiveStage()
  //     .then((res) => {
  //       console.log(res);
  //       let data = res.data.data;
  //       const result = data.map((ele) => ({
  //         key: ele.id,
  //         value: ele.id,
  //         label: ele.name,
  //       }));
  //       setLoading(false);
  //       setStageDropDown(result);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setLoading(false);
  //     });
  // };
  const getAllActiveItemSubGroup = () => {
    setLoading(true);
    // const body = {
    //   item_group_id: itemGroup.value,
    //   scope_id: budget_scope,
    // };
    GetBudgetSubGroupByGroup(budget_id, itemGroup.value)
      .then((res) => {
        let data = res.data.data;
        console.log(data);
        const result = data.map((ele) => ({
          key: ele.subgroupid,
          value: ele.subgroupid,
          label: ele.subgroupname,
        }));
        setLoading(false);
        setitemSubGroupOption(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getStatus = (initialValue) => {
    if (initialValue.purchaseorderitemdetails != null) {
      return true;
      // if(initialValue.purchaseorderitemdetails.purchaseorder.purchase_order_status == "Send To Client"){

      //       return false;
      // }
    }
    return false;
  };
  const getAllActiveItemCategory = () => {
    setLoading(true);
    GetBudgetCategory(budget_id)
      .then((res) => {
        console.log("%%%%%%%%%%%%%%%%%%%%", res.data.data);
        let data = res.data.data;
        console.log(data);
        const result = data.map((ele) => ({
          key: ele.categoryid,
          value: ele.categoryid,
          label: ele.categoryname,
        }));
        setLoading(false);
        SetItemCategoryDropDown(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getAllActiveUOM = () => {
    setLoading(true);
    GetAllActiveUOM()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setLoading(false);
        SetUomDropDown(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const getAllActiveCurrency = () => {
    setLoading(true);
    AllActiveStatusCurrency()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setLoading(false);
        SetcurrencyDropDown(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const GetAllActiveVendor = () => {
    setLoading(true);
    getAllActiveVendor()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          value: ele.id,
          label: ele.name,
        }));
        setLoading(false);
        SetvendorDropDown(result);
        setSpecifiedvendorDropDown(result);
        setshipvendorDropDown(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const GetAllActiveRequiredSubmittals = () => {
    setLoading(true);
    AllActiveStatusRequiredSubmittals()
      .then((res) => {
        let data = res.data.data;
        const result = data.map((ele) => ({
          key: ele.id,
          requiredSubmittals: ele.name,
          checked: initialValue.submittalsdetails.some(
            (item) => item.requiredsubmittals.id === ele.id
          ),
        }));
        setLoading(false);
        SetRequiredSubmittalsDropDown(result);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (budget_id !== null) {
      getAllActiveItemCategory();
    }
    getAllActiveItemGroup();
    // getAllActiveItemCategory();
    // getAllActiveStage();
    getAllActiveUOM();
    getAllActiveCurrency();
    GetAllActiveVendor();
    GetAllActiveRequiredSubmittals();
  }, []);

  React.useEffect(() => {
    console.log(itemGroup.value);
    if (itemGroup.value !== "") {
      getAllActiveItemSubGroup();
    }
  }, [itemGroup.value]);

  const handleDraftData = () => {
    // const specified_vendor = [];
    // specified_vendor.push(specifiedVendor.value);
    // console.log("VAL",vendor)
    // const Vendor_id = vendor.value.map((ele) => {
    //   return ele.key;
    // });
    const budgetItemData = {
      budget_id: parseInt(
        budget_id === undefined ? initialValue.budget.id : budget_id
      ),
      // serial_number: itemSerialNo.value,
      control_number: itemSerialNo.value,
      name: itemName.value,
      description: itemDescription.value,
      item_group_id: itemGroup.value,
      item_stage_id: "1",
      item_subgroup_id: itemSubGroup.value,
      item_category_id: itemCategory.value,
      brand: itemBrand.value,
      tags: tags.value,
      budget_uom_id: budgetUOM.value,
      budget_quantity: budgetQuantity.value,
      budget_currency_id: budgetCurrency.value,
      budget_price: parseFloat(budgetPrice.value),
      actual_uom_id: actualUOM.value,
      actual_quantity: actualQuantity.value,
      actual_currency_id: actualCurrency.value,
      actual_price: parseFloat(actualPrice.value),
      specified_vendor: specifiedVendor.value ? specifiedVendor.value : 0,
      vendor: vendor.value ? vendor.value : 0,
      ship_to_vendor: shiptoVendor.value ? shiptoVendor.value.toString() : "",
      model_number: modelNumber.value.toString(),
      size: size.value,
      notes: notes.value,
      required_submittal: functionResultTrue(RequiredSubmittalsDropDown),
      file_path: selectedFile.value,
      purchase_order_details: [],
    };
    CreateDraftItems(budgetItemData)
      .then((res) => {
        setLoading(true);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        getDraftItems();
        setLoading(false);
        handleModalClose();
        draftStatusFunction(res);
      })
      .catch((e) => {
        // setMessage(e.response.data.message);
        setMessage(e);
      });
  };
 
  const handleSaveData = () => {
    // const specified_vendor = [];
    // specified_vendor.push(specifiedVendor.value)
    // const Vendor_id = vendor.value.map((ele) => {
    //   return ele.key;
    // });

    const budgetItemData = {
      budget_id: parseInt(
        budget_id === undefined ? initialValue.budget.id : budget_id
      ),
      // control_number: itemSerialNo.value,
      control_number:
        actionType === "edit" ? itemSerialNo.value : itemSerialNo.value,
      name: itemName.value,
      description: itemDescription.value,
      item_group_id: itemGroup.value,
      item_stage_id: "1",
      item_subgroup_id: itemSubGroup.value,
      item_category_id: itemCategory.value,
      brand: itemBrand.value,
      tags: tags.value,
      budget_uom_id: budgetUOM.value,
      budget_quantity: budgetQuantity.value,
      budget_currency_id: budgetCurrency.value,
      budget_price: parseFloat(budgetPrice.value),
      actual_uom_id: actualUOM.value,
      actual_quantity: actualQuantity.value,
      actual_currency_id: actualCurrency.value,
      actual_price: parseFloat(actualPrice.value),
      specified_vendor: specifiedVendor.value ? specifiedVendor.value : 0,
      vendor: vendor.value ? vendor.value : 0,
      ship_to_vendor: shiptoVendor.value ? shiptoVendor.value.toString() : "",
      model_number: modelNumber.value.toString(),
      size: size.value,
      brand: brandName.value,
      notes: notes.value,
      required_submittal: functionResultTrue(RequiredSubmittalsDropDown),
      file_path: selectedFile.value,
      purchase_order_details:
        actionType === "edit"
          ? initialValue.purchaseorderitemdetails?.is_active
            ? [
                // {
                //   id: initialValue?.purchaseorderitemdetails?.id,
                //   actual_quantity: actualQuantity.value,
                //   unit_price: (
                //     parseFloat(actualPrice.value) / actualQuantity.value
                //   ).toFixed(2),
                //   total_price: parseFloat(actualPrice.value),
                //   actual_uom_id: actualUOM.value,
                // },
                {
                  id: initialValue?.purchaseorderitemdetails?.id,
                  actual_quantity: actualQuantity.value,
                  unit_price: parseFloat(actualPrice.value),
                  total_price: parseFloat(
                    actualPrice.value * actualQuantity.value
                  ),
                  actual_uom_id: actualUOM.value,
                },
              ]
            : []
          : [],
    };
    console.log(budgetItemData);
    handleBudgetItemData(budgetItemData);
    
    
  };

  const functionResultTrue = (RequiredSubmittalsDropDown) => {
    const resultArray = [];
    RequiredSubmittalsDropDown.forEach((func) => {
      if (func.checked === true) {
        resultArray.push(func.key);
      }
    });
    return resultArray;
  };

  const handleChangeSerial = (value) => {
    if (value && value.value.length >= 3) {
      setItemSerialNo({
        value: value.value,
        error: false,
        success: false,
      });
    }
  };
  const handleChangeItemName = (value) => {
    setItemName(value);
  };
  const handleChangeitemDescription = (value) => {
    setItemDescription(value);
  };
  const handleChangeitemGroup = (value) => {
    setItemGroup(value);
  };
  // const handleChangeStage = (value) => {
  //   setStage(value);
  // };
  const handleChangeitemSubGroup = (value) => {
    let result = itemSubGroupOption.filter((ele) => ele.value == value.value);
    if (result.length > 0) {
      console.log("CCCCCCCCCC", result);
      setItemSerialNo({
        value: result[0].label.substring(0, 3),
      });
    }
    setItemSubGroup(value);
  };
  const handleChangeitemCategory = (value) => {
    setItemCategory(value);
  };

  const handleChangeitemBrand = async (data) => {
    setItemBrand(data);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleChangetags = (value) => {
    setTags(value);
  };
  const handleChangeModelNumber = (value) => {
    setModelNumber(value);
  };
  const handleChangeBrand = (value) => {
    setBrandName(value);
  };
  const handleChangeFile = async (e) => {
    const filename = e.target.files[0].name;
    var fileExtension = filename.split(".").pop();
    const files = e.target.files[0];
    const base64 = await convertBase64(files);
    var newbase64 = base64.replace(/^data:image\/[a-z]+;base64,/, "");
    const formData = {
      file_data: newbase64,
      file_extension: fileExtension,
    };
    setLoading(true);
    ImageCreation(formData)
      .then((res) => {
        setLoading(false);
        setSelectedFile(res.data.data.file_path);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleChangeBudgetUOM = (value) => {
    setBudgetUOM(value);
    setActualUOM(value);
  };
  const handleChangeBudgetQuantity = (value) => {
    setBudgetQuantity(value);
    // setActualQuantity(value);
    // console.log("********************",parseFloat(value))
    // console.log("********************",parseFloat(value.value))

    // setTotalBudgetPrice(parseFloat(value.value)*parseFloat(budgetPrice.value))
    // setTotalActualPrice(parseFloat(value.value)*parseFloat(actualPrice.value))
    // if(value.value == 0){
    //   setBudgetCurrency("");
    //   setBudgetPrice("");
    // }
  };
  const handleChangeBudgetCurrency = (value) => {
    setBudgetCurrency(value);
    setActualCurrency(value);
  };
  const handleChangebudgetPrice = (value) => {
    setBudgetPrice(value);
    // setActualPrice(value);
    // setTotalBudgetPrice(parseFloat(value.value)*parseFloat(budgetQuantity.value))
    // setTotalActualPrice(parseFloat(value.value)*parseFloat(actualQuantity.value))
  };
  const handleChangeactualUOM = (value) => {
    setActualUOM(value);
  };
  const handleChangeActualQuantity = (value) => {
    setActualQuantity(value);
    // setTotalActualPrice(parseFloat(value.value)*parseFloat(actualPrice.value))
  };
  const handleChangeactualCurrency = (value) => {
    setActualCurrency(value);
  };
  const handleChangeactualPrice = (value) => {
    setActualPrice(value);
    // setTotalActualPrice(parseFloat(value.value)*parseFloat(actualQuantity.value))
  };

  const handleChangeSpecifiedVendor = (value) => {
    setSpecifiedVendor(value);
  };
  const handleChangeVendor = (value) => {
    console.log("VALUE", value);
    setVendor(value);
  };
  const handleChangeShiptoVendor = (value) => {
    setShiptoVendor(value);
  };
  const handleChangeSize = (value) => {
    setSize(value);
  };
  const handleChangeNotes = (value) => {
    setNotes(value);
  };
  const handleChangeCheck = (key) => {
    const result = RequiredSubmittalsDropDown.map((label) => {
      if (label.key === key) {
        return {
          ...label,
          checked: !label.checked,
        };
      } else {
        return {
          ...label,
        };
      }
    });
    SetRequiredSubmittalsDropDown(result);
  };

  let isValueNotChanged =
    itemName.value === initialValue.name &&
    itemDescription.value === initialValue.itemDescription &&
    itemGroup.value === initialValue.itemGroup &&
    itemSubGroup.value === initialValue.itemSubGroup &&
    itemCategory.value === initialValue.itemCategory &&
    itemBrand.value === initialValue.itemBrand &&
    tags.value === initialValue.tags &&
    budgetUOM.value === initialValue.budgetUOM &&
    budgetQuantity.value === initialValue.budgetQuantity &&
    budgetCurrency.value === initialValue.budgetCurrency &&
    budgetPrice.value === initialValue.budgetPrice &&
    actualUOM.value === initialValue.actualUOM &&
    actualQuantity.value === initialValue.actualQuantity &&
    actualCurrency.value === initialValue.actualCurrency &&
    actualPrice.value === initialValue.actualPrice &&
    specifiedVendor.value === initialValue.specifiedVendor &&
    vendor.value === initialValue.vendor &&
    shiptoVendor.value === initialValue.shiptoVendor &&
    modelNumber.value === initialValue.modelNumber &&
    brandName.value === initialValue.brandName &&
    size.value === initialValue.size &&
    notes.value === initialValue.notes &&
    // stage.value === initialValue.stage &&
    itemSerialNo.value === initialValue.control_number;

  let disable =
    itemName.error ||
    itemName.value === "" ||
    itemGroup.error ||
    itemGroup.value === "" ||
    itemSubGroup.value === "" ||
    itemSubGroup.error ||
    itemCategory.value === "" ||
    itemCategory.error ||
    // stage.value === "" ||
    // stage.error ||
    itemSerialNo.value === "" ||
    itemSerialNo.error ||
    // budgetUOM.error ||
    // budgetUOM.value === "" ||
    // budgetQuantity.error ||
    // budgetQuantity.value === "" ||
    // budgetCurrency.value === "" ||
    // budgetCurrency.error ||
    // budgetPrice.value === "" ||
    // budgetPrice.error ||
    (itemName.success === false &&
      itemGroup.success === false &&
      itemSubGroup.success === false &&
      itemCategory.success === false &&
      tags.success === false &&
      itemSerialNo.success === false &&
      // stage.success === false &&
      budgetUOM.success === false &&
      budgetQuantity.success === false &&
      budgetCurrency.success === false &&
      budgetPrice.success === false &&
      actualUOM.success === false &&
      actualQuantity.success === false &&
      actualCurrency.success === false &&
      actualPrice.success === false &&
      specifiedVendor.success === false &&
      vendor.success === false &&
      shiptoVendor.success === false &&
      modelNumber.success === false &&
      brandName.success === false &&
      size.success === false &&
      notes.success === false) ||
    isValueNotChanged;

  const createBudgetItemHeader = strings["BudgetItem.createBudgetItemHeader"],
    editBudgetItemHeader = strings["BudgetItem.editBudgetItemHeader"],
    itemSerialLabel = strings["BudgetItem.itemSerialLabel"],
    itemNameLAbel = strings["BudgetItem.itemNameLAbel"],
    itemNamePlaceholder = strings["BudgetItem.itemNamePlaceholder"],
    itemNameError = strings["BudgetItem.itemNameError"],
    itemDescriptionLabel = strings["BudgetItem.itemDescriptionLabel"],
    itemDescriptionPlaceholder =
      strings["BudgetItem.itemDescriptionPlaceholder"],
    itemDescriptionError = strings["BudgetItem.itemDescriptionError"],
    itemGroupLabel = strings["BudgetItem.itemGroupLabel"],
    itemGroupErrorLine = strings["BudgetItem.itemGroupErrorLine"],
    itemGroupSelectText = strings["BudgetItem.itemGroupSelectText"],
    itemSubGroupLabel = strings["BudgetItem.itemSubGroupLabel"],
    itemSubGroupErrorLine = strings["BudgetItem.itemSubGroupErrorLine"],
    itemSubGroupSelectText = strings["BudgetItem.itemSubGroupSelectText"],
    itemCategoryLabel = strings["BudgetItem.itemCategoryLabel"],
    itemCategoryErrorLine = strings["BudgetItem.itemCategoryErrorLine"],
    itemCategorySelectText = strings["BudgetItem.itemCategorySelectText"],
    itemBrandLabel = strings["BudgetItem.itemBrandLabel"],
    itemBrandPlaceholder = strings["BudgetItem.itemBrandPlaceholder"],
    itemBrandError = strings["BudgetItem.itemBrandError"],
    tagsLabel = strings["BudgetItem.tagsLabel"],
    tagsPlaceholder = strings["BudgetItem.tagsPlaceholder"],
    tagsError = strings["BudgetItem.tagsError"],
    budgetUOMLabel = strings["BudgetItem.budgetUOMLabel"],
    budgetUOMErrorLine = strings["BudgetItem.budgetUOMErrorLine"],
    budgetUOMSelectText = strings["BudgetItem.budgetUOMSelectText"],
    budgetQuantityLabel = strings["BudgetItem.budgetQuantityLabel"],
    budgetQuantityPlaceholder = strings["BudgetItem.budgetQuantityPlaceholder"],
    budgetQuantityError = strings["BudgetItem.budgetQuantityError"],
    budgetCurrencyLabel = strings["BudgetItem.budgetCurrencyLabel"],
    budgetCurrencyErrorLine = strings["BudgetItem.budgetCurrencyErrorLine"],
    budgetCurrencySelectText = strings["BudgetItem.budgetCurrencySelectText"],
    budgetPriceLabel = strings["BudgetItem.budgetPriceLabel"],
    budgetPricePlaceholder = strings["BudgetItem.budgetPricePlaceholder"],
    budgetPriceError = strings["BudgetItem.budgetPriceError"],
    actualtUOMLabel = strings["BudgetItem.actualtUOMLabel"],
    actualUOMErrorLine = strings["BudgetItem.actualUOMErrorLine"],
    actualUOMSelectText = strings["BudgetItem.actualUOMSelectText"],
    actualQuantityLabel = strings["BudgetItem.actualQuantityLabel"],
    actualQuantityPlaceholder = strings["BudgetItem.actualQuantityPlaceholder"],
    actualQuantityError = strings["BudgetItem.actualQuantityError"],
    actualCurrencyLabel = strings["BudgetItem.actualCurrencyLabel"],
    actualCurrencyErrorLine = strings["BudgetItem.actualCurrencyErrorLine"],
    actualCurrencySelectText = strings["BudgetItem.actualCurrencySelectText"],
    actualPriceLabel = strings["BudgetItem.actualPriceLabel"],
    actualPricePlaceholder = strings["BudgetItem.actualPricePlaceholder"],
    actualPriceError = strings["BudgetItem.actualPriceError"],
    specifiedVendorLabel = strings["BudgetItem.specifiedVendorLabel"],
    specifiedVendorErrorLine = strings["BudgetItem.specifiedVendorErrorLine"],
    specifiedVendorSelectText = strings["BudgetItem.specifiedVendorSelectText"],
    vendorLabel = strings["BudgetItem.vendorLabel"],
    vendorErrorLine = strings["BudgetItem.vendorErrorLine"],
    vendorSelectText = strings["BudgetItem.vendorSelectText"],
    shiptoVendorLabel = strings["BudgetItem.shiptoVendorLabel"],
    shiptoVendorErrorLine = strings["BudgetItem.shiptoVendorErrorLine"],
    shiptoVendorSelectText = strings["BudgetItem.shiptoVendorSelectText"],
    modelNumberLabel = strings["BudgetItem.modelNumberLabel"],
    modelNumberPlaceholder = strings["BudgetItem.modelNumberPlaceholder"],
    modelNumberError = strings["BudgetItem.modelNumberError"],
    brandNameError = strings["BudgetItem.brandNameError"],
    sizeLabel = strings["BudgetItem.sizeLabel"],
    sizePlaceholder = strings["BudgetItem.sizePlaceholder"],
    sizeError = strings["BudgetItem.sizeError"],
    notesLabel = strings["BudgetItem.notesLabel"],
    notesPlaceholder = strings["BudgetItem.notesPlaceholder"],
    notesError = strings["BudgetItem.notesError"],
    saveBtn = strings["BudgetItem.saveBtn"],
    updateBtn = strings["BudgetItem.updateBtn"],
    cancelBtn = strings["BudgetItem.cancelBtn"],
    itemSubmittalHeader = strings["BudgetItem.itemSubmittalHeader"],
    brandNameLabel = strings["BudgetItem.brandNameLabel"],
    actualTotalPriceLabel = strings["BudgetItem.actualTotalPriceLabel"],
    budgetTotalPriceLabel = strings["BudgetItem.budgetTotalPriceLabel"];
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  const [currenttype, setCurrentType] = React.useState({});
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalCloses = () => {
    setMessage(null);
    setIsOpen(false);
  };
  const handleSelectBtn = (btn) => {
    setMessage(null);
    setClickedBtn(btn);
  };
  const createVendor = (formData) => {
    setLoading(true);
    CreateVendor(formData)
      .then((res) => {
        if (res.data.data.authdata == "") {
          swal({
            title: "Success!",
            text: "Created Successfully",
            icon: "success",
          });
        }
        setLoading(false);
        GetAllActiveVendor();
        handleModalCloses();
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    return (
      <VendorForm
        handleChangeFormData={clickedBtn === "add" ? createVendor : null}
        errorMessage={errorMessage}
        setMessage={setMessage}
        actionType={clickedBtn}
        initialValue={clickedBtn !== "add" ? currenttype : {}}
        handleModalClose={handleModalCloses}
      />
    );
  };

  useImperativeHandle(ref, () => ({
   
    // resetForm()  {
      
    //   setItemName(" ");
    //   setTags("");
    //   setItemDescription("");
    //   setBudgetQuantity("0");
    //   setBudgetUOM("");
    //   setBudgetCurrency("");
    //   setBudgetPrice(" ");
    //   setTotalBudgetPrice("");
    //   setActualQuantity("");
    //   setActualPrice(" ");
    //   setActualQuantity("")
    //   setActualUOM("");
    //   setTotalActualPrice("");
    //   setModelNumber("");
    //   setBrandName("");
    //   setNotes("");
    //   setSize("");
    //   setSelectedFile(null);
    // }
    
  }));

  return (
    <div className="container">
      <div className="row">
        {loading ? <LoaderComponent /> : null}
        <Modal
          onHandleClose={handleModalCloses}
          isOpen={isOpen}
          contentStyle={{ width: "80%" }}
        >
          {selectModal()}
        </Modal>
        <h2>
          {actionType === "add" ? createBudgetItemHeader : editBudgetItemHeader}
        </h2>
        {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}

        <h1>Item List</h1>
        <div className="row">
          <div className="col-md-6" id="select-style">
            <FormTextInput
              type="textarea"
              label={itemNameLAbel}
              placeholder={itemNamePlaceholder}
              Value={itemName.value}
              onChangeText={handleChangeItemName}
              Validate={true}
              CustomErrorLine={itemNameError}
              editable={true}
              starProps={true}
            />
          </div>
          <div className="col-md-6" id="select-style">
            <FormTextInput
              type="textarea"
              label={tagsLabel}
              placeholder={tagsPlaceholder}
              Value={tags.value}
              onChangeText={handleChangetags}
              Validate={tags.value && true}
              CustomErrorLine={tagsError}
              editable={true}
              starProps={false}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4" id="select-style">
            <FormDropDown
              Label={itemGroupLabel}
              Options={ItemGroupDropDown}
              value={itemGroup.value}
              onSelect={handleChangeitemGroup}
              customErrorLine={itemGroupErrorLine}
              selectText={itemGroupSelectText}
              searchable={false}
              starProps={true}
              isValidate={true}
              disable={actionType !== "edit" ? false : true}
            />
          </div>
          <div className="col-md-4" id="select-style">
            <FormDropDown
              Label={itemSubGroupLabel}
              Options={itemSubGroupOption}
              value={itemSubGroup.value}
              onSelect={handleChangeitemSubGroup}
              customErrorLine={itemSubGroupErrorLine}
              selectText={itemSubGroupSelectText}
              searchable={false}
              starProps={true}
              isValidate={true}
              disable={actionType !== "edit" ? false : true}
            />
          </div>
          <div className="col-md-4" id="select-style">
            <FormDropDown
              Label={itemCategoryLabel}
              Options={ItemCategoryDropDown}
              value={itemCategory.value}
              onSelect={handleChangeitemCategory}
              customErrorLine={itemCategoryErrorLine}
              selectText={itemCategorySelectText}
              searchable={false}
              starProps={true}
              isValidate={true}
            />
          </div>
        </div>

        <div className="row">
          {/* <div className="col-12 col-md-6 pe-0 pe-md-3" id="select-style">
          <FormTextInput
            type="textarea"
            label={itemBrandLabel}
            placeholder={itemBrandPlaceholder}
            Value={itemBrand.value}
            onChangeText={handleChangeitemBrand}
            Validate={itemBrand.value && true}
            CustomErrorLine={itemBrandError}
            editable={true}
            starProps={false}
          />
        </div> */}

          {actionType !== "edit" ? (
            <div className="col-md-4" id="select-style">
              <FormTextInput
                type="alpha"
                label={itemSerialLabel}
                placeholder={"Enter Ctrl. No"}
                Value={itemSerialNo.value}
                Validate={true}
                editable={itemSubGroup.value ? true : false}
                starProps={true}
                onChangeText={handleChangeSerial}
                CustomErrorLine={"Enter Ctrl. No"}
                maxLength={9}
              />
            </div>
          ) : (
            <div className="col-md-4" id="select-style">
              <FormTextInput
                type="alpha"
                label={itemSerialLabel}
                placeholder={"Enter Ctrl. No"}
                Value={itemSerialNo.value}
                Validate={true}
                editable={false}
                starProps={true}
                onChangeText={handleChangeSerial}
                CustomErrorLine={"Enter Ctrl. No"}
              />
            </div>
          )}
          <div className="col-md-8" id="select-style">
            <FormTextInput
              type="textarea"
              label={itemDescriptionLabel}
              placeholder={itemDescriptionPlaceholder}
              Value={itemDescription.value}
              onChangeText={handleChangeitemDescription}
              Validate={false}
              CustomErrorLine={itemDescriptionError}
              editable={true}
              multiline={true}
              starProps={false}
            />
          </div>
        </div>
        <>
          <h1>Quantity List</h1>
          <div className="row">
            <div className="col-md-4" id="select-style">
              <FormTextInput
                type="number"
                label={budgetQuantityLabel}
                placeholder={budgetQuantityPlaceholder}
                Value={budgetQuantity.value}
                onChangeText={handleChangeBudgetQuantity}
                Validate={false}
                CustomErrorLine={budgetQuantityError}
                editable={budgetDetail.budget_lock ? false : true}
                starProps={false}
                min={0}
              />
            </div>
            <div className="col-md-4" id="select-style">
              <FormDropDown
                Label={budgetUOMLabel}
                Options={UomDropDown}
                value={budgetUOM.value}
                onSelect={handleChangeBudgetUOM}
                customErrorLine={budgetUOMErrorLine}
                selectText={budgetUOMSelectText}
                searchable={false}
                starProps={false}
                isValidate={false}
                disable={budgetDetail.budget_lock ? true : false}
              />
            </div>
            <div className="col-md-4" id="select-style">
              <FormDropDown
                Label={budgetCurrencyLabel}
                Options={currencyDropDown}
                value={budgetCurrency.value}
                onSelect={handleChangeBudgetCurrency}
                customErrorLine={budgetCurrencyErrorLine}
                selectText={budgetCurrencySelectText}
                searchable={false}
                starProps={false}
                isValidate={false}
                disable={budgetDetail.budget_lock ? true : false}
              />
            </div>
          </div>
          <div className="row">
          <div className="col-md-6" id="select-style">
              <FormTextInput
                type="number"
                label={budgetPriceLabel}
                placeholder={budgetPricePlaceholder}
                Value={budgetPrice.value}
                onChangeText={handleChangebudgetPrice}
                Validate={false}
                CustomErrorLine={budgetPriceError}
                editable={budgetDetail.budget_lock ? false : true}
                starProps={false}
              />
            </div>
            <div className="col-md-6" id="select-style">
              <FormTextInput
                type="number"
                label={budgetTotalPriceLabel}
                placeholder={budgetTotalPriceLabel}
                Value={
                  parseFloat(budgetPrice.value) *
                  parseFloat(budgetQuantity.value)
                }
                editable={false}
                starProps={false}
                disabled={false}
              />
            </div>
          
          </div>
          <div className="row">
            <div className="col-md-4" id="select-style">
              <FormTextInput
                type="number"
                label={actualQuantityLabel}
                placeholder={actualQuantityPlaceholder}
                Value={actualQuantity.value}
                onChangeText={handleChangeActualQuantity}
                Validate={actualQuantity.value && true}
                CustomErrorLine={actualQuantityError}
                editable={true}
                starProps={false}
                min={0}
              />
            </div>
            <div className="col-md-4" id="select-style">
              <FormDropDown
                Label={actualtUOMLabel}
                Options={UomDropDown}
                value={actualQuantity.value > 0 ? actualUOM.value : null}
                onSelect={handleChangeactualUOM}
                customErrorLine={actualUOMErrorLine}
                selectText={actualUOMSelectText}
                searchable={false}
                starProps={false}
                // disable={actualQuantity.value == "" ? true : false}
              />
            </div>
            <div className="col-md-4" id="select-style">
              <FormDropDown
                Label={actualCurrencyLabel}
                Options={currencyDropDown}
                value={actualQuantity.value > 0 ? actualCurrency.value : null}
                onSelect={handleChangeactualCurrency}
                customErrorLine={actualCurrencyErrorLine}
                selectText={actualCurrencySelectText}
                searchable={false}
                starProps={false}
                // disable={actualQuantity.value == "" ? true : false}
              />
            </div>
          
          </div>
          <div className="row">
          <div className="col-md-6" id="select-style">
              <FormTextInput
                type="number"
                label={actualPriceLabel}
                placeholder={actualPricePlaceholder}
                Value={actualQuantity.value > 0 ? actualPrice.value : "0"}
                onChangeText={handleChangeactualPrice}
                Validate={actualPrice.value && true}
                CustomErrorLine={actualPriceError}
                editable={true}
                starProps={false}
                // disabled={actualQuantity.value == "" ? true : false}
              />
            </div>
            <div className="col-md-6" id="select-style">
              <FormTextInput
                type="number"
                label={actualTotalPriceLabel}
                placeholder={actualTotalPriceLabel}
                Value={
                  parseFloat(actualPrice.value) *
                  parseFloat(actualQuantity.value)
                }
                editable={false}
                starProps={false}
                disabled={false}
              />
            </div>
          </div>
          <h1>Vendor Details</h1>
          <div className="row align-items-center justify-content-between">
            <div className="col-md-3" id="select-style">
              <FormDropDown
                defaultValue={specifiedVendor.value}
                Label={specifiedVendorLabel}
                Options={specifiedVendorDropDown}
                value={specifiedVendor.value}
                onSelect={handleChangeSpecifiedVendor}
                customErrorLine={specifiedVendorErrorLine}
                selectText={specifiedVendorSelectText}
                searchable={false}
                isMulti={false}
              />
            </div>
            <div className="col-md-3" id="select-style">
              <FormDropDown
                defaultValue={vendor.value}
                Label={vendorLabel}
                Options={vendorDropDown}
                value={vendor.value}
                onSelect={handleChangeVendor}
                customErrorLine={vendorErrorLine}
                selectText={vendorSelectText}
                searchable={false}
                isMulti={false}
                disable={getStatus(initialValue)}
              />
            </div>
            <div className="col-md-3" id="select-style">
              <FormDropDown
                Label={shiptoVendorLabel}
                Options={shipVendorDropDown}
                value={shiptoVendor.value}
                onSelect={handleChangeShiptoVendor}
                customErrorLine={shiptoVendorErrorLine}
                selectText={shiptoVendorSelectText}
                searchable={false}
              />
            </div>
            <div className="col-2 mt-5">
              <span className="addButton">
                <IconButton
                  onClick={() => {
                    openAddModal();
                    handleSelectBtn("add");
                  }}
                >
                  <AddIcon />
                </IconButton>
              </span>
            </div>
          </div>

          <h1>Model Details</h1>
          <div className="row align-items-center">
            <div className="col-md-4" id="select-style">
              <FormTextInput
                type="textarea"
                label={modelNumberLabel}
                placeholder={modelNumberPlaceholder}
                Value={modelNumber.value}
                onChangeText={handleChangeModelNumber}
                Validate={modelNumber.value && true}
                starProps={false}
                CustomErrorLine={modelNumberError}
                editable={true}
              />
            </div>
            <div className="col-md-4" id="select-style">
              <FormTextInput
                type="textarea"
                label={brandNameLabel}
                placeholder={brandNameLabel}
                Value={brandName.value}
                onChangeText={handleChangeBrand}
                Validate={brandName.value && true}
                starProps={false}
                CustomErrorLine={brandNameError}
                editable={true}
              />
            </div>
            <div className="col-md-4" id="select-style">
              <label
                style={{
                  fontWeight: 400,
                  color: "rgb(0, 0, 0)",
                }}
              >
                Image Upload{" "}
              </label>
              <input
                onChange={(e) => handleChangeFile(e)}
                type="file"
                accept=".jpeg, .png, .jpg"
                // style={{ marginTop: "10px" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormTextInput
                type="textarea"
                label={notesLabel}
                placeholder={notesPlaceholder}
                Value={notes.value}
                onChangeText={handleChangeNotes}
                Validate={notes.value && true}
                starProps={false}
                CustomErrorLine={notesError}
                editable={true}
                multiline={true}
              />
            </div>
            <div className="col-12 col-md-6 pe-0 pe-md-3">
              <FormTextInput
                type="textarea"
                label={sizeLabel}
                placeholder={sizePlaceholder}
                Value={size.value}
                onChangeText={handleChangeSize}
                Validate={size.value && true}
                starProps={false}
                CustomErrorLine={sizeError}
                editable={true}
                multiline={true}
              />
            </div>

            {actionType !== "add" ? (
              <div className="row d-flex justify-content-between align-items-center mt-2">
                <div style={{ display: "flex" }}>
                  <h4>{itemSubmittalHeader}</h4>
                </div>
                {RequiredSubmittalsDropDown.map((functionName) => {
                  return (
                    <div className="col-12 col-md-6">
                      <FormCheckBox
                        key={functionName.key}
                        Label={functionName.requiredSubmittals}
                        checked={functionName.checked}
                        handleChangeCheck={() =>
                          handleChangeCheck(functionName.key)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </>
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center mt-2">
              <Button
                buttonName={cancelBtn}
                buttonStyle={{ backgroundColor: colors.darkgrey }}
                onClick={() => {
                  handleModalClose();
                }}
              />
              {actionType === "editDraft" || actionType === "add" ? (
                <Button
                  buttonName={"Save As Draft"}
                  buttonStyle={{ backgroundColor: colors.darkgrey }}
                  onClick={() => {
                    handleDraftData();
                  }}
                />
              ) : null}
              <Button
                buttonName={actionType === "add" ? saveBtn : updateBtn}
                buttonStyle={{ backgroundColor: colors.primary }}
                disable={actionType === "add" ? disable : false}
                onClick={handleSaveData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default BudgetItemForm;
