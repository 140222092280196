/* eslint-disable no-unused-vars */
import { BlobProvider } from "@react-pdf/renderer";
import { Update } from "@material-ui/icons";
import { ForwardToInbox, InventoryOutlined } from "@mui/icons-material";
import PublishIcon from "@mui/icons-material/Publish";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import DraftsIcon from "@mui/icons-material/Drafts";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PreviewIcon from "@mui/icons-material/Preview";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Card, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal as ReviseModal,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import {
  CreatePurchaseOrder,
  GetAllActivePO,
  GetAllDeletePO,
  GetAllPOByBudget,
  GetAllPurchaseOrderDataByQid,
  GetPOHistory,
  GetProjectById,
  PurchaseOrderFileUploads,
  UpdateCommentDeliveryDate,
  UpdateOrderStatus,
  RevokeApproval,
  UpdatePurchaseOrder,
  UpdatePurchaseOrderActiveStatus,
  GetAllPOByBudgetByClient,
  GetProjectSpecificApprovers,
} from "../../../axios";
import {
  AddIcon,
  AlertMessage,
  BreadCrumbs,
  Chip,
  Modal as ExistModal,
  IconButton,
  NodataFound,
  AlertMessageWithFeedback,
  Button as ButtonComponent,
} from "../../../components";
import LoaderComponent from "../../../components/common/Loader";
import ModalPdf from "../../../components/common/ModalPdf";
import { Style } from "../../../components/common/style";
import { colors } from "../../../configs";
import { CreatePurchaseOrdersForm } from "../../../forms";
import strings from "../../../translations";
import { isAuthorizedFunc } from "../../../utils";
import {
  CHANGE_PURCHASE_ORDER_STATUS_FUNCTION,
  CREATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
} from "../../../utils/constants";
import { isDecoded, isEncoded } from "../../../utils/crypto";
import PurchaseOrderReport from "./PurchaseOrderDownload";
import PurchaseOrderFileUpload from "./PurchaseOrderFileUpload";
import { getItemFromStorage } from "../../../utils/storage";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

import SearchCheckedTables from "../../../components/AppSpecific/SearchTable/SearchCheckedTables";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const SearchPurchaseOrdersPage = () => {
  const poDataType = localStorage.getItem("directPO");

  const location = useLocation();
  const history = useHistory();
  let proId = location.state
    ? location.state.proId
    : location.query
    ? JSON.parse(location.query.params).proId
    : null;
  console.log(proId);
  let budget_id = location?.state
    ? location.state.budget_id
    : location.query
    ? JSON.parse(location.query.params).budget_id
    : null;

  const fetchId = useParams();
  const paramId = isDecoded(fetchId.id);
  const paramRfqId = parseInt(paramId);

  const rfqId = location?.state
    ? location.state.rfqId
    : location.query
    ? JSON.parse(location.query.params).rfqId
    : paramRfqId;

  let scope_id = location?.state
    ? location?.state?.scope_id
    : location?.query
    ? JSON.parse(location.query.params).scope_id
    : null;

  let budget_Name = location?.state
    ? location.state?.budget_name
    : location?.query
    ? JSON.parse(location.query.params).budget_name
    : null;

  if (
    scope_id == null ||
    (scope_id == undefined && budget_Name == null) ||
    (budget_Name == undefined && proId == null) ||
    (proId == undefined && budget_id == null) ||
    budget_id == undefined
  ) {
    const paramData = JSON.parse(getItemFromStorage("PARAMS"));
    if (paramData) {
      budget_id = paramData.budget_id;
      proId = paramData.proId;
      budget_Name = paramData.budget_Name;
      scope_id = paramData.budget_scope;
    }
  }

  const encodeProId = isEncoded(proId);
  const encodeBudId = isEncoded(budget_id);
  const encodedRfqId = isEncoded(rfqId);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenDraft, setIsOpenDraft] = React.useState(false);
  const [clickedBtn, setClickedBtn] = React.useState("");
  const [currentUserType, setCurrentUserType] = React.useState({});
  const [data, setData] = React.useState([]);
  const [alertBox, setAlertBox] = React.useState(false);
  const [alertSubmission, setAlertSubmission] = React.useState(false);
  const [alertClientSubmission, setAlertClientSubmission] =
    React.useState(false);
  const [statusChangeId, setStatusChangeId] = React.useState(null);
  const [isLoader, setisLoader] = React.useState(false);
  const [currentStatus, setcurrentStatus] = React.useState(null);
  const [errorMessage, setMessage] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalData, setTotalData] = React.useState(null);
  const [isAdd, setAdd] = React.useState(true);
  const [isUpdate, setIsUpdate] = React.useState(true);
  const [isStatus, setIsStatus] = React.useState(true);
  const [isRevisedOpen, setIsRevisedOpen] = React.useState(false);
  const [isReviseOpen, setIsReviseOpen] = React.useState(false);
  const [isViewOpen, setIsViewOpen] = React.useState(false);
  const [isPoViewOpen, setPoIsViewOpen] = React.useState(false);
  const [draftItem, setDraftItem] = React.useState([]);
  const [draftModals, setModals] = React.useState(true);
  const [draftDatas, setDraftDatas] = React.useState([]);
  const [poHistoryId, setPoHistoryId] = React.useState(null);
  const [poHistoryData, setPoHistoryData] = React.useState([]);
  const [purchaseData, setPurchaseData] = React.useState([]);
  const [purchaseId, setPurchaseId] = React.useState();
  const [purchaseDownldData, setPurchaseDownldData] = React.useState();
  const [projectDetails, setProjectDetails] = React.useState({});
  const [clientRemark, setClientRemark] = React.useState("");
  // const [comment, setComment] = React.useState("");
  // const [setDeliveryDate, setSetDeliveryDate] = React.useState("");
  const [viewPoData, setViewPoData] = useState([]);
  const [pODeliveryData, setSetPODeliveryData] = React.useState();
  const [rowData, setRowData] = useState([]);
  const [comments, setComments] = useState([]);
  const [deliveryDates, setDeliveryDates] = useState([]);
  const [encodePurchaseId, setEncodePurchaseId] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const [isApprove, setApprove] = useState(false);
  const [isReject, setReject] = useState(false);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [selectItem, setSelectItem] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [projectApprovers, setProjectApprovers] = React.useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = data.filter((purchaseData) =>
    purchaseData.purchase_order_serial_number
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  console.log({ purchaseData });

  console.log(comments);
  console.log(deliveryDates);
  // Function to handle input changes for comments
  const handleCommentChange = (index, value) => {
    const updatedComments = [...comments];
    updatedComments[index] = value;
    setComments(updatedComments);
  };

  // Function to handle input changes for delivery dates
  const handleDeliveryDateChange = (index, value) => {
    const updatedDeliveryDates = [...deliveryDates];
    updatedDeliveryDates[index] = value;
    setDeliveryDates(updatedDeliveryDates);
  };
  React.useEffect(() => {
    if (isAuthorizedFunc(CREATE_PURCHASE_ORDER)) {
      setAdd(true);
    } else {
      setAdd(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(UPDATE_PURCHASE_ORDER)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, []);

  React.useEffect(() => {
    if (isAuthorizedFunc(CHANGE_PURCHASE_ORDER_STATUS_FUNCTION)) {
      setIsStatus(true);
    } else {
      setIsStatus(false);
    }
  }, []);

  var paths = [];

  if (poDataType == "true") {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: "purchaseorders",
      },
    ];
  } else {
    paths = [
      {
        name: strings["Projects"],
        path: "project",
      },
      {
        name: strings["Budgets"],
        path: `projectbudget/${encodeProId}`,
      },
      {
        name: strings["Budget Items"],
        path: `budgetitem/${encodeBudId}`,
      },
      {
        name: strings["Rfq"],
        path: `rfq/${encodeBudId}`,
      },
      {
        name: strings["Quotation"],
        path: `quotation/${encodedRfqId}`,
      },
      {
        name: strings["costanlysisText"],
        path: `costanalysis/${encodedRfqId}`,
      },
      {
        name: strings["CostReport"],
        path: `searchcostreport/${encodedRfqId}`,
      },
      {
        name: strings["PurchaseOrder"],
        path: "purchaseorders",
      },
    ];
  }

  const FurnishTitle = strings["Title.Furnish"],
    PurchaseOrder = strings["PurchaseOrder"],
    SearchPurchaseOrdersPage =
      strings["PurchaseOrders.SearchPurchaseOrdersPage"],
    alertTitle = strings["PurchaseOrders.alertTitle"],
    alertMessage = strings["PurchaseOrders.alertMessage"],
    alertSubmitToClient = strings["PurchaseOrders.submitToClientAlert"],
    alertApprovalFromClient = strings["PurchaseOrders.approveFromClientAlert"],
    alertRejectionFromClient = strings["PurchaseOrders.rejectFromClientAlert"],
    statusLabel = strings["PurchaseOrders.statusLabel"],
    referredRRQlabel = strings["PurchaseOrders.referredRRQlabel"],
    purchaseOrdersNameLabel = strings["PurchaseOrders.purchaseOrdersNameLabel"],
    vendorLabel = strings["PurchaseOrders.vendorLabel"],
    uploadSignedPoLabel = strings["PurchaseOrders.uploadSignedPoLabel"],
    referredQuotationLabel = strings["PurchaseOrders.referredQuotationLabel"],
    serialLabel = strings["PurchaseOrders.serialLabel"],
    name = strings["BudgetScope.TableHead.name"],
    email = strings["Authentication.loginEmailLabel"],
    designation = strings["TableHead.designation"],
    isSignatoryLabel = strings["ProjectSpecificUser.SignatoryNameLabel"],
    submit = strings["PurchaseOrders.submitBtn"],
    cancel = strings["SubMital.CancelBtn"],
    actionLabel = strings["PurchaseOrders.actionLabel"];

  const mapableData = [
    {
      dataKey: "name",
      title: name,
    },
    {
      dataKey: "email",
      title: email,
    },
    {
      dataKey: "designation",
      title: designation,
    },
    {
      dataKey: "is_approver",
      title: isSignatoryLabel,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  // const handleClose = () => {
  //   setIsRevisedOpen(false);
  // };
  const handledClose = () => {
    setIsReviseOpen(false);
    setPoHistoryData([]);
    setPoHistoryId(null);
    setIsViewOpen(false);
    setPoIsViewOpen(false);
  };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value));
  //   setPage(0);
  // };
  const openAddModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
    setIsOpenDraft(() => {});
    setModals(true);
  };
  const handleSelectBtn = (btn) => {
    setClickedBtn(btn);
  };
  const hanleDraftStatus = (id) => {
    setIsRevisedOpen(true);
  };
  const handleReviseStatus = (id) => {
    setIsReviseOpen(true);
    setPoHistoryId(id);
    getRevisePo(id);
  };
  const handleView = (id) => {
    setPurchaseId(id);
    const result = data.find((elem) => {
      return elem.id === id;
    });

    console.log({ result });
    // setSetDeliveryDate(
    //   result.item_details ? result.item_details[0].delivery_date : null
    // );
    // setComment(
    //   result.item_details ? result.item_details[0]?.add_comment : null
    // );
    setViewPoData(result);
    setIsViewOpen(true);
  };

  React.useEffect(() => {
    setEncodePurchaseId(isEncoded(purchaseId));
  }, [purchaseId]);

  const handleLineItems = (id) => {
    const result = data.find((elem) => {
      return elem.id === id;
    });

    setViewPoData(result);
    setPoIsViewOpen(true);
  };
  const handleChangeActiveStatus = (id) => {
    const result = data.find((elem) => {
      return elem.id === id;
    });
    setStatusChangeId(id);
    setcurrentStatus(result.is_active);
    setAlertBox(true);
  };

  const handleChangeSuccess = () => {
    setisLoader(true);
    UpdatePurchaseOrderActiveStatus(statusChangeId)
      .then((res) => {
        setisLoader(false);
        const updateData = data.filter((elem) => {
          return elem.id !== res.data.data.id;
        });
        setData(updateData);
        setAlertBox(false);
      })
      .catch(() => {
        setisLoader(false);
      });
  };

  const getProjectSpecificApprovers = () => {
    setisLoader(true);
    // setLoading(true);
    GetProjectSpecificApprovers(proId)
      .then((res) => {
        // setLoading(false);
        setisLoader(false);
        setProjectApprovers(res.data[0]["project_specific_approvers"]);
      })
      .catch(() => {
        setisLoader(false);
        // setLoading(false);
      });
  };
  const filterData = (data) => {
    const newArr = [];
    if (data != null) {
      data.forEach((item) => {
        let result = {
          ...item["clientcontacts"],
          is_approver: item["is_approver"] ? "Yes" : "No",
          checked: item.hasOwnProperty("checked") ? item["checked"] : false,
        };
        newArr.push(result);
      });
    }
    return newArr;
  };

  const handleUpdate = (id, index) => {
    console.log(viewPoData.item_details[index].add_comment);
    const body = {
      add_comment:
        comments.length !== 0
          ? comments[index]
          : viewPoData.item_details[index].add_comment,
      delivery_date:
        deliveryDates.length !== 0
          ? deliveryDates[index]
          : viewPoData.item_details[index].delivery_date,
    };
    setisLoader(true);
    UpdateCommentDeliveryDate(id, body)
      .then((res) => {
        setisLoader(false);

        setSetPODeliveryData(res.data.data);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setComments([]);
        setDeliveryDates([]);
        getPurchaseOrder();
      })
      .catch(() => {
        setisLoader(false);
        setComments([]);
        setDeliveryDates([]);
      });
  };
  const handleChangeCancel = () => {
    setAlertBox(false);
    setAlertSubmission(false);
    setAlertClientSubmission(false);
    setApprove(false);
    setReject(false);
  };

  const getProjectDetails = () => {
    GetProjectById(proId)
      .then((res) => {
        setProjectDetails(res.data.data);
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    getProjectDetails();
  }, []);

  const getAlldata = () => {
    setisLoader(true);
    GetAllPurchaseOrderDataByQid(rfqId)
      .then((res) => {
        console.log(res);
        setTotalData(res.data.total);
        setisLoader(false);
        setData(res.data.data);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  console.log(data);
  const getPurchaseOrder = () => {
    setisLoader(true);
    GetAllPOByBudget(budget_id)
      .then((res) => {
        console.log("res", res);
        // setComment(res.data.data[0]?.item_details[0]?.add_comment);
        // setSetDeliveryDate(res.data.data[0]?.item_details[0]?.delivery_date);
        setisLoader(false);
        setData(res.data.data);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  const getPurchaseOrderByClient = () => {
    setisLoader(true);
    GetAllPOByBudgetByClient(budget_id)
      .then((res) => {
        setisLoader(false);
        setData(res.data.data);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };

  React.useEffect(() => {
    if (poDataType == "false") {
      getAlldata();
    } else {
      if (user.loginType != "client") {
        getPurchaseOrder();
      }
      if (user.loginType == "client") {
        getPurchaseOrderByClient();
      }
    }
  }, [status, page, rowsPerPage]);

  const [poStatusDrop, setPoStatusDrop] = React.useState([
    { key: 1, value: "Draft", label: "Draft" },
    { key: 2, value: "Send To Client", label: "Send To Client" },
    { key: 3, value: "Signed", label: "Signed" },
    { key: 4, value: "Send To Vendor", label: "Send To Vendor" },
    { key: 5, value: "Cancelled", label: "Cancelled" },
  ]);

  const createPurchaseOrder = (formData) => {
    setisLoader(true);
    CreatePurchaseOrder(formData)
      .then((res) => {
        // GetAllPOByBudget(budget_id);
        if (poDataType == "false") {
          getAlldata();
        } else {
          getPurchaseOrder();
        }
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const updatePurchaseOrder = (formData) => {
    console.log(formData);
    setisLoader(true);
    const poId = currentUserType.id;
    UpdatePurchaseOrder(poId, formData)
      .then((res) => {
        // GetAllPOByBudget(budget_id);
        if (poDataType == "false") {
          getAlldata();
        } else {
          getPurchaseOrder();
        }
        handleModalClose();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const getDraftItems = () => {
    GetAllActivePO()
      .then((res) => {
        console.log(res);
        setDraftItem(res.data.data);
      })
      .catch(() => {});
  };
  const getRevisePo = () => {
    setisLoader(true);
    GetPOHistory(poHistoryId)
      .then((res) => {
        console.log("%%%%%%%%%%%%", res);
        setisLoader(false);
        setPoHistoryData(res.data.data);
      })
      .catch((e) => {
        setisLoader(false);
      });
  };
  React.useEffect(() => {
    getDraftItems();
  }, []);
  React.useEffect(() => {
    getRevisePo();
  }, [poHistoryId]);

  const draftStatusFunction = (val) => {
    getDraftItems();
  };

  const DraftUpdateFunc = (id) => {
    setModals(false);
  };
  const DraftDeleteFunc = (id) => {
    setisLoader(true);
    GetAllDeletePO(id)
      .then((res) => {
        if (res.status === 200) {
          setisLoader(false);
          getDraftItems();
        }
      })
      .catch(() => {
        setisLoader(false);
      });
  };
  const draftCreateFunction = (formData) => {
    setisLoader(true);
    CreatePurchaseOrder(formData)
      .then((res) => {
        getAlldata();
        setisLoader(false);
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        handleModalClose();
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };
  const handleSelectData = (id) => {
    const result = projectApprovers.map((data) => {
      if (data.clientcontacts.id === id) {
        return { ...data, checked: !data["checked"] };
      } else {
        return { ...data };
      }
    });
    let isDifferent = false;

    let checkedDatas = result.filter((doc) => doc.checked === true);
    // checkedDatas.forEach((doc) => {
    //   for (let i of checkedDatas) {
    //     if (doc.clientcontacts.id !== i.clientcontacts.id) {
    //       isDifferent = true;
    //     }
    //   }
    // });
    if (checkedDatas.length == 0) {
      isDifferent = true;
    }

    setBtnDisable(isDifferent);
    setProjectApprovers(result);
    setSelectItem(checkedDatas);
  };
  const handleUploadFile = (data) => {
    setisLoader(true);
    PurchaseOrderFileUploads(purchaseId, data)
      .then((res) => {
        if (res.status === 200) {
          getAlldata();
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setisLoader(false);
          handleModalClose();
        }
      })
      .catch((e) => {
        setisLoader(false);
        setMessage(e.response.data.message);
      });
  };

  const selectModal = () => {
    if (clickedBtn === "editDraft") {
      return (
        <CreatePurchaseOrdersForm
          draftCreateFunction={draftCreateFunction}
          rfqId={rfqId}
          actionType={clickedBtn}
          initialValue={clickedBtn !== "add" ? currentUserType : {}}
          handleModalClose={handleModalClose}
          setMessage={setMessage}
          errorMessage={errorMessage}
          draftStatusFunction={draftStatusFunction}
          proId={proId}
        />
      );
    } else if (clickedBtn === "New CSV File") {
      return (
        <PurchaseOrderFileUpload
          actionType={"add"}
          handleModalClose={handleModalClose}
          handleChangeData={handleUploadFile}
        />
      );
    } else if (clickedBtn === "Submit To Client") {
      return (
        <>
          {projectApprovers && (
            <>
              <h3 className="my-3">Purchase Order Approvers</h3>
              <div className="col-12">
                <SearchCheckedTables
                  data={filterData(projectApprovers)}
                  mapableData={mapableData}
                  searchProp={["name"]}
                  handleChangeCheck={handleSelectData}
                  showSelectAll={false}
                  // handleView={handleView}
                  // handleChangeEdit = {handleChangeEdit}
                />
              </div>
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    marginTop: "1.5rem",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonComponent
                    buttonName={cancel}
                    buttonStyle={{ backgroundColor: colors.primary }}
                    onClick={() => {
                      handleModalClose();
                    }}
                  />
                  <ButtonComponent
                    buttonName={submit}
                    buttonStyle={{
                      backgroundColor: colors.primary,
                      marginLeft: "10px",
                    }}
                    onClick={handleSubmitToClient}
                    disable={btnDisable}
                  />
                </div>
              </div>
            </>
          )}
        </>
      );
    } else {
      return (
        <CreatePurchaseOrdersForm
          poType={poDataType}
          handleOrdersData={
            clickedBtn === "add" ? createPurchaseOrder : updatePurchaseOrder
          }
          rfqId={rfqId}
          actionType={clickedBtn}
          initialValue={clickedBtn !== "add" ? currentUserType : {}}
          handleModalClose={handleModalClose}
          setMessage={setMessage}
          errorMessage={errorMessage}
          draftStatusFunction={draftStatusFunction}
          draftCreateFunction={(data) => {
            setDraftDatas(data);
          }}
          proId={proId}
          projectDetails={projectDetails}
        />
      );
    }
  };
  const purchaseOrderId = (id) => {
    const result = data.find((ele) => {
      return ele.id === id.id;
    });
    setPurchaseData(result);
  };

  const handlecsvUploadItem = (id) => {
    openAddModal();
    handleSelectBtn("New CSV File");
  };
  const [isDownloadOpen, setIsDownloadOpen] = React.useState(false);

  const openDownloadModal = () => {
    setIsDownloadOpen(true);
  };
  const handleDownloadModalClose = () => {
    setIsDownloadOpen(false);
  };

  const handleChangeDownload = () => {
    openDownloadModal();
  };
  // const handleOpenInNewTab = () => {
  //   window.open(<PurchaseOrderReport poData={purchaseDownldData} />, "_blank"); // Opens a route in a new tab
  // };

  const selectDownloadModal = () => {
    return <PurchaseOrderReport poData={purchaseDownldData} />;
  };
  const [pages, setPages] = useState(0);
  const [rowsPerPages, setRowsPerPages] = useState(5);

  const handleChangePages = (event, newPage) => {
    setPages(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPages(parseInt(event.target.value, 10));
    setPages(0);
  };
  const [orderStatus] = React.useState([
    { key: 1, value: "Draft", label: "Draft" },
    // { key: 2, value: "Send To Client", label: "Send To Client" },
    // { key: 3, value: "Signed By Client", label: "Signed By Client" },
    { key: 4, value: "Send To Vendor", label: "Send To Vendor" },
    { key: 5, value: "Signed By Vendor", label: "Signed By Vendor" },
    { key: 6, value: "Cancelled", label: "Cancelled" },
    { key: 7, value: "Revision", label: "Revision" },
    { key: 8, value: "Revoke", label: "Revoke To Draft" },
  ]);

  const handleChangeStatus = (e, pdi) => {
    const selectedStatus = e.target.innerHTML;

    if (selectedStatus === "Revoke To Draft") {
      RevokeApproval(pdi)
        .then((res) => {
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setStatus("Draft");
        })
        .catch((err) => {
          console.error("Error revoking to draft:", err);
        });
    } else {
      const statusValue = {
        purchase_order_status: selectedStatus,
      };

      UpdateOrderStatus(pdi, statusValue)
        .then((res) => {
          swal({
            title: "Success!",
            text: res.data.message,
            icon: "success",
          });
          setStatus(res.data.data.purchase_order_status);
        })
        .catch(() => {});
    }
  };

  const getIDs = (data) => {
    let newArr = [];
    data.forEach((data) => {
      newArr.push(data.clientcontacts.id);
    });
    return newArr;
  };

  const handleSendToClient = () => {
    setisLoader(true);
    const body = {
      action_by: user.id,
      purchase_order_status: "Send To Client",
      project_id: proId,
      client_remark: "NA",
      client_ids: getIDs(selectItem),
    };
    UpdateOrderStatus(purchaseId, body)
      .then((res) => {
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setStatus(res.data.data.purchase_order_status);
        setAlertSubmission(false);
        setisLoader(false);
        setIsOpen(false);
      })
      .catch((e) => {
        swal({
          title: "Error!",
          text: e.response.data.message,
          icon: "error",
        });
        setAlertSubmission(false);
        setisLoader(false);
        setIsOpen(false);
      });
  };

  const handleSubmitToClient = () => {
    handleSendToClient();
  };

  const handleRemarkFromClient = () => {
    setisLoader(true);
    const body = {
      action_by: user.id,
      purchase_order_status: isApprove
        ? "Signed By Client"
        : "Rejected By Client",
      project_id: proId,
      client_remark: clientRemark,
    };
    UpdateOrderStatus(purchaseId, body)
      .then((res) => {
        swal({
          title: "Success!",
          text: res.data.message,
          icon: "success",
        });
        setStatus(res.data.data.purchase_order_status);
        setAlertClientSubmission(false);
        setisLoader(false);
      })
      .catch(() => {
        setAlertClientSubmission(false);
        setisLoader(false);
      });
  };

  const handleClientRemark = (val) => {
    setClientRemark(val);
  };

  const showApproveRejectStatus = (data) => {
    return (
      Array.isArray(data) && !!data.find((item) => item.client_id === user.id)
    );
  };

  const isActionTaken = (data) => {
    return (
      Array.isArray(data) &&
      data.filter((item) => item.is_approved === null).length == data.length
    );
  };

  const styles = {
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      color: "black",
      width: "100%",
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    image: {
      width: "200px",
      height: "50px",
    },
    section: {
      margin: 10,
      padding: 10,
      alignItems: "flex-end",
      borderBottom: "1px solid black",
      marginRight: "25px",
      marginLeft: "25px",
    },
    textLine: {
      fontSize: "6px",
      paddingBottom: "3px",
    },
    textLineBold: {
      fontSize: "6px",
      fontWeight: "900",
    },
    footer: {
      position: "absolute",
      bottom: "0px",
      width: "100%",
      backgroundColor: "#fff",
      height: "100px",
      margin: "0px 25px",
    },
    pageNo: {
      right: "-500px",
      fontSize: "6px",
    },
    textSummery: {
      marginLeft: "25px",
      marginRight: "25px",
      fontSize: "6px",
      marginTop: "15px",
    },
    pageNumbers: {
      position: "absolute",
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: "10",
    },
  };

  function calculateAdvancePaymentPercentage(data) {
    if (data?.length === 0) {
      console.log("!!!!!!!!!!!!!!!!!!!!!!!");
      return 0;
    }

    const filteredItems = data?.filter(
      (item) => item.purchase_order_payment_term_id === 1
    );
    if (filteredItems?.length === 0) {
      console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
      return 0;
    }
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );

    // const totalPercentage = (filteredItems[0]?.value / totalValue) * 100;
    if (
      filteredItems &&
      (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0")
    ) {
      return 0;
    }

    return (
      filteredItems &&
      `${filteredItems[0]?.value} ${
        filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
      } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`
    );
  }
  function calculateProgressPaymentPercentage(data) {
    if (data?.length === 0) {
      return 0;
    }
    const filteredItems = data?.filter(
      (item) => item.purchase_order_payment_term_id === 3
    );
    if (filteredItems?.length === 0) {
      return "0";
    }

    // Sum up the 'value' for filtered items
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );

    // Calculate total percentage
    // const totalPercentage = (filteredItems[0]?.value / totalValue) * 100;

    if (
      filteredItems &&
      (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0")
    ) {
      return 0;
    }
    return (
      filteredItems &&
      `${filteredItems[0]?.value} ${
        filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
      } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`
    );
  }

  function calculateFinalPaymentPercentage(data) {
    if (data?.length === 0) {
      return 0;
    }
    const filteredItems = data?.filter(
      (item) => item.purchase_order_payment_term_id === 4
    );
    console.log(filteredItems);
    if (filteredItems?.length === 0) {
      return "0";
    }
    // Sum up the 'value' for filtered items
    // const totalValue = poData.PurchaseOrderPaymentTermDetails.reduce(
    //   (sum, item) => sum + parseInt(item.value),
    //   0
    // );
    // console.log(totalValue);
    // Calculate total percentage
    // const totalPercentage =
    //   (parseInt(filteredItems[0]?.value) / parseInt(totalValue)) * 100;
    // console.log(totalPercentage);
    if (
      filteredItems &&
      (filteredItems[0]?.value == "" || filteredItems[0]?.value == "0")
    ) {
      return 0;
    }
    return (
      filteredItems &&
      `${filteredItems[0]?.value} ${
        filteredItems[0]?.option_one ? filteredItems[0]?.option_one : ""
      } ${filteredItems[0]?.option_two ? filteredItems[0]?.option_two : ""}`
    );
  }
  return (
    <div className="content-wrapper p-0">
      <Helmet>
        <title>
          {FurnishTitle} | {PurchaseOrder}
        </title>
      </Helmet>
      <ModalPdf
        isOpen={isDownloadOpen}
        onHandleClose={handleDownloadModalClose}
      >
        {selectDownloadModal()}
        {/* {handleOpenInNewTab()} */}
      </ModalPdf>

      {isLoader ? <LoaderComponent /> : null}
      <div
        className="row mt-4"
        style={{
          alignItems: "center",
        }}
      >
        <div
          className="mb-3 d-flex justify-content-between mb-4"
          style={{
            alignItems: "center",
          }}
        >
          <div className="col">
            <h3>{SearchPurchaseOrdersPage}</h3>
            <BreadCrumbs
              pathList={paths}
              obj={{
                proId: proId,
                budget_id: budget_id,
                rfqId: rfqId,
                budget_name: budget_Name,
                scope_id: scope_id,
              }}
            />
          </div>
          <span>
            {user.loginType != "client" && (
              <button
                className={"budgetitembutton me-3"}
                onClick={() => {
                  history.push({
                    pathname: `/paymentsummary/${fetchId.id}`,
                    state: {
                      proId: proId,
                      budget_id: budget_id,
                      budget_name: budget_Name,
                      scope_id: scope_id,
                    },
                  });
                }}
              >
                Payment Summary
              </button>
            )}
          </span>
          <span className="addButton pt-1">
            {user.loginType != "client" && (
              <IconButton>
                <Tooltip title="Draft">
                  <DraftsIcon
                    style={{
                      fontSize: "20px",
                      margin: "5px 5px ",
                      cursor: "pointer",
                      color: colors.primary,
                    }}
                    onClick={() => {
                      setIsOpen(false);
                      setIsOpenDraft(true);
                    }}
                  />
                </Tooltip>
              </IconButton>
            )}
          </span>

          {isAdd && poDataType == "false" && (
            <span className="addButton pt-1">
              <IconButton
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              >
                <AddIcon />
              </IconButton>
            </span>
          )}
        </div>
      </div>
      <ExistModal onHandleClose={handleModalClose} isOpen={isOpen}>
        {selectModal()}
      </ExistModal>
      {draftModals ? (
        <Modal
          isOpen={isOpenDraft}
          style={{
            overlay: Object.assign({}, Style.overlayDraft),
            content: Object.assign({}, Style.contentDraft),
          }}
        >
          <div>
            <div>
              <div className="d-flex justify justify-content-between align-items-center">
                <h2 className="modal-title">PO Draft History</h2>
                <CloseIcon onClick={() => setIsOpenDraft(false)} />
              </div>
              <hr />
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Purchase Order Name</th>
                      <th scope="col">Vendor</th>
                      <th scope="col">Ship To Vendor</th>
                      <th scope="col">Billing Address</th>
                      <th scope="col">Shipping Address</th>
                      <th scope="col">Incoterms</th>
                      <th scope="col">Delivery Location</th>
                      <th scope="col">Required Documents</th>
                      <th scope="col">File</th>
                      <th scope="col">Edit</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {draftItem.map((elem) => {
                      return (
                        <>
                          <tr>
                            <td>{elem.purchase_order_name}</td>
                            <td>{elem.vendor?.name}</td>
                            <td>{elem.ship_to_vendor?.name}</td>
                            <td>{elem.billing_address}</td>
                            <td>{elem.shipping_address}</td>
                            <td>{elem.incoterm.name}</td>
                            <td>{elem.location.name}</td>
                            <td>
                              {elem.purchase_order_required_document.map(
                                (ele) => {
                                  return ele.required_document.name;
                                }
                              )}
                            </td>
                            <td>
                              {elem.purchase_order_files.map((ele) => {
                                return ele.file_path;
                              })}
                            </td>
                            <td>
                              <span className="addButton">
                                <IconButton
                                  onClick={() => {
                                    openAddModal();
                                    DraftUpdateFunc(elem.id);
                                    setCurrentUserType(elem);
                                    handleSelectBtn("editDraft");
                                  }}
                                >
                                  <Tooltip title="Edit">
                                    <EditIcon className={"iconSwitch"} />
                                  </Tooltip>
                                </IconButton>
                              </span>
                            </td>
                            <td>
                              <span className="addButton">
                                <IconButton
                                  onClick={() => {
                                    DraftDeleteFunc(elem.id);
                                  }}
                                >
                                  <Tooltip title="Delete">
                                    <DeleteIcon
                                      className={"iconSwitch"}
                                      sx={{ color: colors.danger }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}

      <ReviseModal
        size="xl"
        show={isReviseOpen}
        onHide={handledClose}
        animation={false}
        centered
      >
        <ReviseModal.Header closeButton>
          <ReviseModal.Title>PO Revision History</ReviseModal.Title>
        </ReviseModal.Header>
        <ReviseModal.Body>
          <Container>
            <Row
              style={{
                color: "black",
                marginBottom: "50px",
                fontWeight: "500",
              }}
            >
              <Col>Po No.</Col>
            </Row>
            <div className="row">
              <div className="col-2">
                {poHistoryData?.map((elem) => {
                  return (
                    <>
                      <Row
                        style={{
                          color: "black",
                          fontWeight: "500",
                        }}
                      >
                        <div className="d-flex align-items-start" style={{}}>
                          <div
                            className="nav flex-column nav-pills me-3"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <button
                              className=" mt-3"
                              id="v-pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-home"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected="true"
                              onClick={() =>
                                purchaseOrderId(elem.purchase_order_revision_id)
                              }
                            >
                              {elem.purchase_order_revision_id}
                            </button>
                          </div>
                        </div>
                      </Row>
                    </>
                  );
                })}
              </div>
              <div
                className="col-10"
                style={{
                  marginTop: "-40px",
                  marginLeft: "-40px",
                }}
              >
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-vender-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-vender"
                      type="button"
                      role="tab"
                      aria-controls="nav-vender"
                      aria-selected="true"
                    >
                      Vendors
                    </button>
                    <button
                      className="nav-link"
                      id="nav-delivery-date-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-delivery-date"
                      type="button"
                      role="tab"
                      aria-controls="nav-delivery-date"
                      aria-selected="false"
                    >
                      Delivery
                    </button>
                    <button
                      className="nav-link"
                      id="nav-address-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-address"
                      type="button"
                      role="tab"
                      aria-controls="nav-address"
                      aria-selected="false"
                    >
                      Address
                    </button>
                    <button
                      className="nav-link"
                      id="nav-lead-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-lead"
                      type="button"
                      role="tab"
                      aria-controls="nav-lead"
                      aria-selected="false"
                    >
                      Lead Time
                    </button>
                    <button
                      className="nav-link"
                      id="nav-documents-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-documents"
                      type="button"
                      role="tab"
                      aria-controls="nav-documents"
                      aria-selected="false"
                    >
                      Required Documents
                    </button>
                    <button
                      className="nav-link"
                      id="nav-required-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-required"
                      type="button"
                      role="tab"
                      aria-controls="nav-required"
                      aria-selected="false"
                    >
                      Required Submittals
                    </button>
                    <button
                      className="nav-link"
                      id="nav-file-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-file"
                      type="button"
                      role="tab"
                      aria-controls="nav-file"
                      aria-selected="false"
                    >
                      File Name
                    </button>
                    <button
                      className="nav-link"
                      id="nav-note-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-note"
                      type="button"
                      role="tab"
                      aria-controls="nav-note"
                      aria-selected="false"
                    >
                      Notes
                    </button>
                  </div>
                </nav>
                <>
                  <div
                    className="tab-content"
                    id="nav-tabContent"
                    style={{
                      paddingLeft: "100px",
                      paddingTop: "20px",
                    }}
                  >
                    <div
                      className="tab-pane fade show active"
                      id="nav-vender"
                      role="tabpanel"
                      aria-labelledby="nav-vender-tab"
                    >
                      Name: {purchaseData?.ship_to_vendor?.name}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-delivery-date"
                      role="tabpanel"
                      aria-labelledby="nav-delivery-date-tab"
                    >
                      Delivery Date: {purchaseData?.created_on?.split(" ")[0]}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-address"
                      role="tabpanel"
                      aria-labelledby="nav-address-tab"
                    >
                      Billing Address:{purchaseData.billing_address}
                      <br />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-transmission"
                      role="tabpanel"
                      aria-labelledby="nav-transmission-tab"
                    >
                      Mode Of Transmission:
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-payment"
                      role="tabpanel"
                      aria-labelledby="nav-payment-tab"
                    >
                      Bank Charges By:
                      <br />
                      Mode of payment:
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-lead"
                      role="tabpanel"
                      aria-labelledby="nav-lead-tab"
                    >
                      {purchaseData.purchase_order_lead_time?.map((ele) => {
                        return (
                          <>
                            <div className="row">
                              <div className="col-6">
                                <p>
                                  Day(s) for Shop Drawing:
                                  <b>{ele?.days_for_shop_drawing}</b>
                                </p>
                                <p>
                                  Day(s) for Wash Test Sample:
                                  <b>{ele?.days_for_wash_test_sample}</b>
                                </p>
                                <p>
                                  Day(s) for Finish Sample:
                                  <b>{ele?.days_for_finish_sample}</b>
                                </p>
                              </div>
                              <div className="col-6">
                                <p>
                                  Day(s) for Production:
                                  <b>{ele?.days_for_production}</b>
                                </p>
                                <p>
                                  Day(s) for Sample/ Prototype:
                                  <b>{ele?.days_for_sample_prototype}</b>
                                </p>
                                <p>
                                  Day(s) for Transit:
                                  <b>{ele?.days_for_transit}</b>
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-documents"
                      role="tabpanel"
                      aria-labelledby="nav-documents-tab"
                    >
                      Required Documents:
                      {purchaseData.purchase_order_required_document?.map(
                        (ele) => {
                          return ele?.required_document.name;
                        }
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-production"
                      role="tabpanel"
                      aria-labelledby="nav-production-tab"
                    >
                      Production Triggers:
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-transit"
                      role="tabpanel"
                      aria-labelledby="nav-transit-tab"
                    >
                      Transit Triggers:
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-required"
                      role="tabpanel"
                      aria-labelledby="nav-required-tab"
                    >
                      Required Submittals:
                      {purchaseData.purchase_order_required_submittals?.map(
                        (ele) => {
                          return ele.required_submittals.name;
                        }
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-file"
                      role="tabpanel"
                      aria-labelledby="nav-file-tab"
                    >
                      File Name:
                      {purchaseData.purchase_order_files?.map((ele) => {
                        return ele.file_path;
                      })}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-note"
                      role="tabpanel"
                      aria-labelledby="nav-note-tab"
                    >
                      Note:{purchaseData.notes}
                    </div>
                  </div>
                </>
              </div>
            </div>
          </Container>
        </ReviseModal.Body>
        <ReviseModal.Footer>
          <Button variant="secondary" onClick={handledClose}>
            Close
          </Button>
        </ReviseModal.Footer>
      </ReviseModal>
      <ReviseModal
        size="xl"
        show={isViewOpen}
        onHide={handledClose}
        animation={false}
        fullscreen="xxl-down"
      >
        <ReviseModal.Header closeButton>
          <ReviseModal.Title>
            Purchase Order
            <span style={{ position: "absolute", right: "5%" }}>
              {user.loginType != "client" && (
                <button
                  className={"invoicebuttonCustom  me-3"}
                  onClick={() => {
                    history.push({
                      pathname: `/deliverynote/${encodePurchaseId}`,
                      state: {
                        proId: proId,
                        budget_id: budget_id,
                        rfqId: rfqId,
                        // invoice_id: invoice?.id,
                        purchase_id: purchaseId,
                      },
                    });
                  }}
                >
                  Add Delivery Note
                </button>
              )}
              {user.loginType != "client" && (
                <button
                  className={"invoicebuttonCustom  me-3"}
                  onClick={() => {
                    history.push({
                      pathname: `/paymentRequest/${encodePurchaseId}`,
                      state: {
                        proId: proId,
                        budget_id: budget_id,
                        rfqId: rfqId,
                        // invoice_id: invoice?.id,
                      },
                    });
                  }}
                >
                  Payment Requests
                </button>
              )}
            </span>
          </ReviseModal.Title>
        </ReviseModal.Header>
        <ReviseModal.Body>
          <Container>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid black",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Vendor Name
                </Typography>
                <Typography
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {viewPoData && viewPoData.vendor
                    ? viewPoData.vendor.name
                    : "N/A"}
                </Typography>
                <Typography
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Project Name
                </Typography>
                <Typography
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {viewPoData &&
                    viewPoData.item_details &&
                    viewPoData.item_details[0].budgetitem.budget.project.name}
                </Typography>
                <Typography
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Purchase order #
                </Typography>
                <Typography
                  style={{
                    width: "10%",
                    fontSize: "10px",
                    lineHeight: "5px",
                    fontWeight: "500",
                    padding: "5px",
                  }}
                >
                  {viewPoData && viewPoData.purchase_order_serial_number
                    ? viewPoData.purchase_order_serial_number
                    : "N/A"}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid black",
                }}
              >
                <Typography
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Vendor Address
                </Typography>
                <Typography
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {" "}
                  {viewPoData &&
                    viewPoData.vendor &&
                    `Fax: ${
                      viewPoData.vendor?.fax != null
                        ? viewPoData.vendor.fax
                        : "NA"
                    }
                  Phone: ${
                    viewPoData.vendor?.phone != null
                      ? viewPoData.vendor.phone
                      : "NA"
                  } 
                  City: ${
                    viewPoData.vendor?.city != null
                      ? viewPoData.vendor.city
                      : ""
                  },${
                      viewPoData.vendor?.postcode != null
                        ? viewPoData.vendor.postcode
                        : ""
                    },${
                      viewPoData.vendor?.state != null
                        ? viewPoData.vendor.state
                        : ""
                    }
                  Address: ${
                    viewPoData.vendor.address
                      ? viewPoData.vendor.address
                      : "N/A"
                  }
                  `}
                </Typography>
                <Typography
                  style={{
                    width: "15%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Budget Number
                </Typography>
                <Typography
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {viewPoData &&
                    viewPoData.item_details &&
                    viewPoData.item_details[0].budgetitem.budget.serial_number}
                </Typography>
                <Typography
                  style={{
                    width: "20%",
                    fontSize: "10px",
                    lineHeight: "10px",
                    fontWeight: "500",
                    borderRight: "1px solid black",
                    padding: "5px",
                    backgroundColor: "rgb(188, 204, 204)",
                  }}
                >
                  Issuing Date
                </Typography>
                <Typography
                  style={{
                    width: "10%",
                    fontSize: "10px",
                    lineHeight: "5px",
                    fontWeight: "500",
                    padding: "5px",
                    borderRight: "1px solid black",
                  }}
                >
                  {/* {moment(
                    poData.item_details[0].budgetitem.budget.budget_start_date.split(
                      " "
                    )[0]
                  ).format("Do MMM YYYY")} */}
                  {/* {
                    poData.item_details[0].budgetitem.budget.budget_start_date.split(
                      " "
                    )[0]
                  } */}
                  {viewPoData &&
                    moment(viewPoData.created_on).format("Do MMM YYYY")}
                </Typography>
              </div>
              <div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: "5px",
                      marginBottom: "10px",
                      borderBottom: "1px solid black",
                    }}
                  >
                    <Typography
                      style={{
                        width: "15%",
                        fontSize: "10px",
                        lineHeight: "10px",
                        fontWeight: "500",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black",
                        padding: "5px",
                        backgroundColor: "rgb(188, 204, 204)",
                      }}
                    >
                      Shipping address
                    </Typography>
                    <Typography
                      style={{
                        width: "20%",
                        fontSize: "10px",
                        lineHeight: "10px",
                        fontWeight: "500",
                        padding: "5px",
                        borderRight: "1px solid black",
                      }}
                    >
                      {viewPoData && viewPoData.shipping_address
                        ? viewPoData.shipping_address
                        : "N/A"}
                    </Typography>
                    <Typography
                      style={{
                        width: "15%",
                        fontSize: "10px",
                        lineHeight: "10px",
                        fontWeight: "500",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "rgb(188, 204, 204)",
                      }}
                    >
                      Billing address
                    </Typography>
                    <Typography
                      style={{
                        width: "20%",
                        fontSize: "10px",
                        lineHeight: "10px",
                        fontWeight: "500",
                        padding: "5px",
                        borderRight: "1px solid black",
                      }}
                    >
                      {viewPoData && viewPoData.billing_address
                        ? viewPoData.billing_address
                        : "N/A"}
                    </Typography>
                    <Typography
                      style={{
                        width: "20%",
                        fontSize: "10px",
                        lineHeight: "10px",
                        fontWeight: "500",
                        borderRight: "1px solid black",
                        padding: "5px",
                        backgroundColor: "rgb(188, 204, 204)",
                      }}
                    >
                      Order Currency
                    </Typography>
                    <Typography
                      style={{
                        width: "10%",
                        fontSize: "10px",
                        lineHeight: "5px",
                        fontWeight: "500",
                        padding: "5px",
                        borderRight: "1px solid black",
                      }}
                    >
                      {viewPoData &&
                      viewPoData.item_details &&
                      viewPoData.item_details[0]?.budgetitem
                        ?.budgetquantitydetails?.actual_currency?.name
                        ? viewPoData.item_details[0].budgetitem
                            .budgetquantitydetails.actual_currency.name
                        : "N/A"}
                    </Typography>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div
                    style={{
                      flexDirection: "row",
                      border: "1px solid black",
                      marginTop: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        backgroundColor: "rgb(188, 204, 204)",
                        fontSize: "6px",
                        borderBottom: "1px solid black",
                        padding: "5px",
                      }}
                    >
                      <Typography style={{ marginLeft: "40%" }}>
                        Special Instructions
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        backgroundColor: "rgb(188, 204, 204)",
                        fontSize: "6px",
                        padding: "5px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Typography>GENERAL</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: "5px",
                        fontSize: "6px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        This purchase order is in &nbsp;
                        {viewPoData?.item_details?.[0]?.budgetitem
                          ?.budgetquantitydetails?.actual_currency?.name
                          ? viewPoData.item_details[0].budgetitem
                              .budgetquantitydetails.actual_currency.name
                          : "N/A"}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        Freight/Delivery Terms:&nbsp;
                        {/* CIF - COST INSURANCE & FREIGHT. */}
                        {viewPoData && viewPoData?.incoterm?.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        Original invoices for any advance payments are to be
                        submitted immediately, matching the line items &
                        conditions of this&nbsp; PO
                      </Typography>
                      {(calculateAdvancePaymentPercentage(
                        viewPoData &&
                          viewPoData?.PurchaseOrderPaymentTermDetails
                      ) != 0 ||
                        calculateProgressPaymentPercentage(
                          viewPoData &&
                            viewPoData?.PurchaseOrderPaymentTermDetails
                        ) != 0 ||
                        calculateFinalPaymentPercentage(
                          viewPoData &&
                            viewPoData?.PurchaseOrderPaymentTermDetails
                        ) != 0) && (
                        <Typography
                          style={{
                            fontSize: "10px",
                            lineHeight: "10px",
                            fontWeight: "500",
                            marginBottom: "3px",
                            marginTop: "3px",
                          }}
                        >
                          Payment Terms:
                        </Typography>
                      )}
                      {calculateAdvancePaymentPercentage(
                        viewPoData && viewPoData.PurchaseOrderPaymentTermDetails
                      ) != 0 ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            lineHeight: "10px",
                            fontWeight: "500",
                            marginBottom: "3px",
                            marginTop: "3px",
                          }}
                        >
                          ADVANCE PAYMENT: &nbsp;
                          {calculateAdvancePaymentPercentage(
                            viewPoData &&
                              viewPoData.PurchaseOrderPaymentTermDetails
                          ) !== 0 ||
                          calculateAdvancePaymentPercentage(
                            viewPoData &&
                              viewPoData.PurchaseOrderPaymentTermDetails
                          ) !== "null"
                            ? `${calculateAdvancePaymentPercentage(
                                viewPoData &&
                                  viewPoData.PurchaseOrderPaymentTermDetails
                              )} `
                            : ""}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {calculateProgressPaymentPercentage(
                        viewPoData && viewPoData.PurchaseOrderPaymentTermDetails
                      ) != 0 && (
                        <Typography
                          style={{
                            fontSize: "10px",
                            lineHeight: "10px",
                            fontWeight: "500",
                            marginBottom: "3px",
                            marginTop: "3px",
                          }}
                        >
                          PROGRESS PAYMENT:&nbsp;
                          {calculateProgressPaymentPercentage(
                            viewPoData &&
                              viewPoData.PurchaseOrderPaymentTermDetails
                          ) !== 0 ||
                          calculateProgressPaymentPercentage(
                            viewPoData &&
                              viewPoData.PurchaseOrderPaymentTermDetails
                          ) !== null
                            ? `${calculateProgressPaymentPercentage(
                                viewPoData &&
                                  viewPoData.PurchaseOrderPaymentTermDetails
                              )} `
                            : ""}
                        </Typography>
                      )}

                      {calculateFinalPaymentPercentage(
                        viewPoData && viewPoData.PurchaseOrderPaymentTermDetails
                      ) != 0 && (
                        <Typography
                          style={{
                            fontSize: "10px",
                            lineHeight: "10px",
                            fontWeight: "500",
                            marginBottom: "3px",
                            marginTop: "3px",
                          }}
                        >
                          FINAL PAYMENT:&nbsp;
                          {calculateFinalPaymentPercentage(
                            viewPoData &&
                              viewPoData.PurchaseOrderPaymentTermDetails
                          ) !== 0 ||
                          calculateFinalPaymentPercentage(
                            viewPoData &&
                              viewPoData.PurchaseOrderPaymentTermDetails
                          ) !== null
                            ? `${calculateFinalPaymentPercentage(
                                viewPoData &&
                                  viewPoData.PurchaseOrderPaymentTermDetails
                              )} `
                            : ""}
                        </Typography>
                      )}

                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        Mode of payment:
                        {viewPoData && viewPoData?.mode_of_payment?.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        This PO is to be read together with Vendor Special
                        Conditions.
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        Go to{" "}
                        <Link src="https://proqware.furnish-me.com/static/media/special-condition-to-the-purchase-order.pdf">
                          Special Conditions to the Purchase Order
                        </Link>
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        backgroundColor: "rgb(188, 204, 204)",
                        fontSize: "6px",
                        padding: "5px",
                        borderBottom: "1px solid black",
                      }}
                    >
                      <Typography>
                        SCHEDULING - From Receipt of this PO
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: "5px",
                        fontSize: "6px",
                      }}
                    >
                      {viewPoData &&
                      viewPoData.PurchaseOrderLeadTime &&
                      viewPoData.PurchaseOrderLeadTime[0]
                        .days_for_finish_sample ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "10px",
                              lineHeight: "10px",
                              fontWeight: "500",
                              marginBottom: "3px",
                              marginTop: "3px",
                            }}
                          >
                            Finish Sample:&nbsp;
                            {/* {viewPoData && viewPoData.item_details[0].quotation !== null
                      ? viewPoData && viewPoData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                            {viewPoData &&
                              viewPoData?.PurchaseOrderLeadTime &&
                              viewPoData?.PurchaseOrderLeadTime[0]
                                ?.days_for_finish_sample}
                            day(s)
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                      {viewPoData &&
                      viewPoData.PurchaseOrderLeadTime &&
                      viewPoData.PurchaseOrderLeadTime[0]
                        .days_for_shop_drawing ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "10px",
                              lineHeight: "10px",
                              fontWeight: "500",
                              marginBottom: "3px",
                              marginTop: "3px",
                            }}
                          >
                            Shop Drawing:&nbsp;
                            {/* {viewPoData && viewPoData.item_details[0].quotation !== null
                      ? viewPoData && viewPoData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                            {viewPoData &&
                              viewPoData?.PurchaseOrderLeadTime &&
                              viewPoData?.PurchaseOrderLeadTime[0]
                                ?.days_for_shop_drawing}
                            day(s)
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                      {viewPoData &&
                      viewPoData.PurchaseOrderLeadTime &&
                      viewPoData.PurchaseOrderLeadTime[0]
                        .days_for_wash_test_sample ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "10px",
                              lineHeight: "10px",
                              fontWeight: "500",
                              marginBottom: "3px",
                              marginTop: "3px",
                            }}
                          >
                            Wash Test Sample:&nbsp;
                            {/* {viewPoData && viewPoData.item_details[0].quotation !== null
                      ? viewPoData && viewPoData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                            {viewPoData &&
                              viewPoData?.PurchaseOrderLeadTime &&
                              viewPoData?.PurchaseOrderLeadTime[0]
                                ?.days_for_wash_test_sample}
                            day(s)
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                      {viewPoData &&
                      viewPoData.PurchaseOrderLeadTime &&
                      viewPoData.PurchaseOrderLeadTime[0]
                        .days_for_sample_prototype ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "10px",
                              lineHeight: "10px",
                              fontWeight: "500",
                              marginBottom: "3px",
                              marginTop: "3px",
                            }}
                          >
                            Sample Prototype:&nbsp;
                            {/* {viewPoData && viewPoData.item_details[0].quotation !== null
                      ? viewPoData && viewPoData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                            {viewPoData &&
                              viewPoData?.PurchaseOrderLeadTime &&
                              viewPoData?.PurchaseOrderLeadTime[0]
                                ?.days_for_sample_prototype}
                            day(s)
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                      {viewPoData &&
                      viewPoData.PurchaseOrderLeadTime &&
                      viewPoData.PurchaseOrderLeadTime[0].days_for_transit ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "10px",
                              lineHeight: "10px",
                              fontWeight: "500",
                              marginBottom: "3px",
                              marginTop: "3px",
                            }}
                          >
                            Production Lead Time:&nbsp;
                            {/* {viewPoData && viewPoData.item_details[0].quotation !== null
                      ? viewPoData && viewPoData.item_details[0].quotation.lead_time.split(" ")[1]
                      : ""}{" "} */}
                            {viewPoData &&
                              viewPoData?.PurchaseOrderLeadTime &&
                              viewPoData?.PurchaseOrderLeadTime[0]
                                ?.days_for_production}
                            day(s)
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                      {viewPoData &&
                      viewPoData.PurchaseOrderLeadTime &&
                      viewPoData.PurchaseOrderLeadTime[0].days_for_transit ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "10px",
                              lineHeight: "10px",
                              fontWeight: "500",
                              marginBottom: "3px",
                              marginTop: "3px",
                            }}
                          >
                            Transit Lead Time:&nbsp;
                            {viewPoData &&
                              viewPoData.PurchaseOrderLeadTime &&
                              viewPoData.PurchaseOrderLeadTime[0]
                                .days_for_transit}{" "}
                            day(s)
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* <Typography style={styles.textLine}>
                  Expected Arrival at&nbsp;
                  <Typography style={styles.textLineBold}>PORT AT DESTINATION</Typography>
                </Typography> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        backgroundColor: "rgb(188, 204, 204)",
                        fontSize: "6px",
                        padding: "5px",
                        borderBottom: "1px solid black",
                        borderTop: "1px solid black",
                      }}
                    >
                      <Typography>DOCUMENTATION & LOGISTICS</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        fontSize: "6px",
                        padding: "5px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        Vendor should provide originals of:{" "}
                        {viewPoData &&
                          viewPoData.PurchaseOrderRequiredDocument &&
                          viewPoData.PurchaseOrderRequiredDocument.map(
                            (item) => item.required_document[0].name
                          ).join(", ")}
                      </Typography>
                      {/* <Typography style={styles.textLineBold}>{viewPoData && viewPoData.Location.name}</Typography> */}
                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        All goods and packaging to be clearly labelled with
                        control numbers and descriptions with corresponding
                        control numbers on all documentation.
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10px",
                          lineHeight: "10px",
                          fontWeight: "500",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        Any packaging charges or other surcharges not quoted for
                        cannot be charged.
                      </Typography>
                      {/* <Typography
                  style={{
                    fontSize: "6px",
                    fontWeight: "900",
                    paddingTop: "5px",
                  }}
                >
                  DELIVERY WITHIN 10 WEEKS FROM THE DATE OF ORDER CONFIRMATION
                  AND ADVANCE PAYMENT.
                </Typography> */}
                      {/* <Typography
                  style={{
                    fontSize: "6px",
                    fontWeight: "900",
                    paddingTop: "5px",
                  }}
                >
                  WIRE TRANSFER FEES TO BE COVERED BY BUYER.
                </Typography> */}
                      {viewPoData && viewPoData.notes !== "" ? (
                        <>
                          <Typography
                            style={{
                              fontSize: "10px",
                              lineHeight: "10px",
                              fontWeight: "500",
                              marginBottom: "3px",
                              marginTop: "3px",
                              fontWeight: "900",
                            }}
                          >
                            {/* PORT OF DESTINATION: JEDDAH */}

                            {`*${viewPoData && viewPoData.notes}*`}
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table
              className="table table-bordered"
              style={{ border: "1px solid black" }}
            >
              <thead>
                <tr>
                  {/* Header columns */}
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    CTRL No.
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Sub Group
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    UOM
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Vendor
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Billing Address
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Shipping Address
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Mode Of Payment
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Add Comment
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Set Delivery Date
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Unit Price
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Total Price
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {viewPoData.item_details &&
                viewPoData.item_details.length > 0 ? (
                  viewPoData.item_details.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.budgetitem.control_number}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.budgetitem.itemsubgroup.name}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {`Item Name: ${item.budgetitem.name}
                                  Size: ${
                                    item.budgetitem.budget_modeldetails?.size !=
                                    null
                                      ? item.budgetitem.budget_modeldetails.size
                                      : "NA"
                                  }                          
                                  Brand: ${
                                    item.budgetitem.budget_modeldetails
                                      ?.brand != null
                                      ? item.budgetitem.budget_modeldetails
                                          .brand
                                      : "NA"
                                  }                             
                                  Description: ${item.budgetitem.description}`}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.actual_uom_Name}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.actual_quantity}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {viewPoData.vendor.name}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {viewPoData.billing_address}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {viewPoData.shipping_address}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {viewPoData.mode_of_payment.name}
                      </td>
                      <td>
                        <input
                          type="text"
                          style={{ width: "50px" }}
                          value={comments[index] || ""}
                          onChange={(e) =>
                            handleCommentChange(index, e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          value={deliveryDates[index] || ""}
                          onChange={(e) =>
                            handleDeliveryDateChange(index, e.target.value)
                          }
                        />
                      </td>
                      <td>{item.unit_price}</td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.total_price}
                      </td>
                      <td>
                        {/* <button
                          style={{ margin: "9px", padding: "8px" }}
                          className="btn btn-outline-success"
                          onClick={() => {
                            handleUpdate(viewPoData);
                            handledClose();
                          }}
                        >
                          Update
                        </button> */}
                        <IconButton
                          onClick={() => {
                            handleUpdate(item.id, index);
                            handledClose();
                          }}
                        >
                          <Tooltip title="Update">
                            <Update color="primary" />
                          </Tooltip>
                        </IconButton>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13">No item details found.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid black",
                  marginTop: "15px",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  style={{
                    width: "100%",
                    fontSize: "10px",
                    lineHeight: "5px",
                    fontWeight: "500",
                    borderRight: "1px solid black",
                    padding: "5px",
                    textAlign: "right",
                  }}
                >
                  Total (
                  {viewPoData?.item_details?.[0]?.budgetitem
                    ?.budgetquantitydetails?.actual_currency?.name
                    ? viewPoData.item_details[0].budgetitem
                        .budgetquantitydetails.actual_currency.name
                    : "N/A"}
                  )
                </Typography>
                <CurrencyFormat
                  value={
                    viewPoData &&
                    viewPoData.item_details &&
                    viewPoData &&
                    viewPoData.item_details
                      .reduce((acc, curr) => {
                        return acc + parseFloat(curr.total_price);
                      }, 0)
                      .toFixed(2)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                    <Typography
                      style={{
                        width: "15%",
                        fontSize: "10px",
                        lineHeight: "5px",
                        fontWeight: "500",
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      {value}
                    </Typography>
                  )}
                />
                {/* <Text
                style={{
                  width: "15%",
                  fontSize: "6px",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                {poData.item_details &&
                  poData.item_details
                    .reduce((acc, curr) => {
                      return acc + parseInt(curr.total_price);
                    }, 0)
                    .toFixed(2)}
              </Text> */}
              </div>
            </div>

            <Typography
              style={{
                fontSize: "10px",
                lineHeight: "10px",
                fontWeight: "500",
                marginBottom: "5px",
                marginTop: "30px",
              }}
            >
              This Purchase Order ("PO") is prepared by FURNISH Hospitality
              General Trading LLC ("Agent") on behalf of the Client, the details
              of which are set out above ("Project Name"/"Billing Address") and
              as agent only for the Client. It consists of this cover sheet and
              the attached special conditions, the content of which the Vendor
              acknowledges and agrees to by signing this PO. All purchases are
              being made solely for the account of the Client. The Client shall
              bear the sole responsibility for all payments. All correspondence
              and other business pertaining to acceptance of this PO and the
              terms set herein, including, without limitation, credit approval,
              terms, and payment shall be between the Vendor and the Client and
              the Agent assumes no liability therefor, and makes no
              representations to the Vendor or the Client express or implied.
            </Typography>
          </Container>
        </ReviseModal.Body>
        <ReviseModal.Footer>
          <Button variant="secondary" onClick={handledClose}>
            Close
          </Button>
        </ReviseModal.Footer>
      </ReviseModal>
      <ReviseModal
        size="xl"
        show={isPoViewOpen}
        onHide={handledClose}
        animation={false}
        fullscreen="xxl-down"
      >
        <ReviseModal.Header closeButton>
          <ReviseModal.Title>Budget Items</ReviseModal.Title>
        </ReviseModal.Header>
        <ReviseModal.Body>
          <Container>
            <table className="table table-bordered">
              <thead>
                <tr>
                  {/* Header columns */}
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    CTRL No.
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Sub Group
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    UOM
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Vendor
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Billing Address
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Shipping Address
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Mode Of Payment
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Comment
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                    className="text-nowrap"
                  >
                    Delivery Date
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Unit Price
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Total Price
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Converted Exchange Currency to AED
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Converted Unit Price
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "10px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Converted Total Price
                  </th>
                </tr>
              </thead>
              <tbody>
                <>{console.log(viewPoData)}</>
                {viewPoData.item_details &&
                viewPoData.item_details.length > 0 ? (
                  viewPoData.item_details.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.budgetitem.control_number}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.budgetitem.itemsubgroup.name}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.budgetitem.description}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.actual_uom_Name}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.actual_quantity}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {viewPoData.vendor.name}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {viewPoData.billing_address}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {viewPoData.shipping_address}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {viewPoData.mode_of_payment.name}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.add_comment}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.delivery_date}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.unit_price}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item.total_price}
                      </td>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {item?.budgetitem?.budgetquantitydetails
                          ?.actual_currency?.name === "AED"
                          ? item?.budgetitem?.budgetquantitydetails
                              ?.actual_currency?.rate
                          : item.budgetitem.budgetquantitydetails?.currency
                              ?.reverse_rate &&
                            item?.budgetitem.budgetquantitydetails
                              .actual_currency?.rate
                          ? (
                              item.budgetitem.budgetquantitydetails?.currency
                                ?.reverse_rate *
                              parseFloat(
                                item?.budgetitem.budgetquantitydetails
                                  .actual_currency?.rate
                              )
                            ).toFixed(2)
                          : "NA"}
                      </td>{" "}
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {(
                          (item?.budgetitem?.budgetquantitydetails
                            ?.actual_currency?.name === "AED"
                            ? item?.budgetitem.budgetquantitydetails
                                ?.actual_currency?.rate
                            : (
                                item.budgetitem.budgetquantitydetails.currency
                                  ?.reverse_rate *
                                parseFloat(
                                  item?.budgetitem.budgetquantitydetails
                                    ?.actual_currency?.rate
                                )
                              ).toFixed(2)) * item?.unit_price
                        ).toFixed(2)}
                      </td>{" "}
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: "20px",
                          fontWeight: "500",
                        }}
                      >
                        {(
                          (item?.budgetitem?.budgetquantitydetails
                            ?.actual_currency?.name === "AED"
                            ? item?.budgetitem.budgetquantitydetails
                                ?.actual_currency?.rate
                            : (
                                item.budgetitem.budgetquantitydetails.currency
                                  ?.reverse_rate *
                                parseFloat(
                                  item?.budgetitem.budgetquantitydetails
                                    ?.actual_currency?.rate
                                )
                              ).toFixed(2)) *
                          item.unit_price *
                          item.actual_quantity
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="13">No item details found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Container>
        </ReviseModal.Body>
        <ReviseModal.Footer>
          <Button variant="secondary" onClick={handledClose}>
            Close
          </Button>
        </ReviseModal.Footer>
      </ReviseModal>
      {alertBox && (
        <AlertMessage
          title={alertTitle}
          message={alertMessage}
          onChangeCancel={handleChangeCancel}
          onChangeSucess={handleChangeSuccess}
        />
      )}
      {alertSubmission && (
        <AlertMessage
          title={alertTitle}
          message={alertSubmitToClient}
          onChangeCancel={handleChangeCancel}
          onChangeSucess={handleSubmitToClient}
        />
      )}
      {alertClientSubmission && (
        <AlertMessageWithFeedback
          title={alertTitle}
          message={
            isApprove ? alertApprovalFromClient : alertRejectionFromClient
          }
          setClientRemark={handleClientRemark}
          onChangeCancel={handleChangeCancel}
          onChangeSucess={handleRemarkFromClient}
        />
      )}

      <Card className="row">
        {data.length !== 0 ? (
          // data.map((purchaseData, index) => {
          //   return (
          //     <div className="col-12 col-md-4" key={index}>
          //       <SearchPageCard>
          //         <SearchPurchaseOrderCard
          //           uid={isEncoded(purchaseData.id)}
          //           allData={purchaseData}
          //           isUpdate={isUpdate}
          //           isStatus={isStatus}
          //           status={status}
          //           setStatus={setStatus}
          //           proId={proId}
          //           budget_id={budget_id}
          //           rfqId={rfqId}
          //           pdi={purchaseData.id}
          //           createdOn={purchaseData.created_on}
          //           pState={purchaseData}
          //           activeStatus={purchaseData.id}
          //           serialLabel={serialLabel}
          //           serialValue={index + 1}
          //           poNo={purchaseData.purchase_order_serial_number}
          //           purchaseOrderValue={purchaseData.purchaseOrdersName}
          //           statusLabel={statusLabel}
          //           statusValue={purchaseData.purchase_order_status}
          //           referredRRQlabel={referredRRQlabel}
          //           referredRRQValue={purchaseData.rfq?.name}
          //           referredQuotationLabel={referredQuotationLabel}
          //           referredQuotationValue={purchaseData.item_details?.map(
          //             (ele) => {
          //               return ele?.quotation?.quotation_name;
          //             }
          //           )}
          //           vendorLabel={vendorLabel}
          //           vendorValue={purchaseData.vendor?.name}
          //           purchaseorderLabel={purchaseOrdersNameLabel}
          //           purchaseOrderNameValue={purchaseData.purchase_order_name}
          //           uploadSignedPoLabel={uploadSignedPoLabel}
          //           uploadSignedPoValue={
          //             purchaseData.PurchaseOrderFiles
          //               ? purchaseData.PurchaseOrderFiles[0]
          //                 ? purchaseData.PurchaseOrderFiles?.map((ele) => {
          //                     return `${ele.file_path} \n`;
          //                   })
          //                 : null
          //               : null
          //           }
          //           draftData={() => {
          //             hanleDraftStatus(purchaseData.id);
          //             setIsOpen(false);
          //             setIsOpenDraft(true);
          //           }}
          //           viewData={() => {
          //             handleView(purchaseData.id);
          //           }}
          //           viewPoData={() => {
          //             handleLineItems(purchaseData.id);
          //           }}
          //           reviseData={() => {
          //             handleReviseStatus(purchaseData.id);
          //           }}
          //           actionLabel={actionLabel}
          //           switchButtonOnClick={() => {
          //             handleChangeActiveStatus(purchaseData.id);
          //           }}
          //           iconButtonOnClick={() => {
          //             openAddModal();
          //             handleSelectBtn("edit");
          //             setCurrentUserType(purchaseData);
          //           }}
          //           poStatusDrop={poStatusDrop}
          //           handlecsvUploadItem={() => {
          //             handlecsvUploadItem();
          //             setPurchaseId(purchaseData.id);
          //           }}
          //           handleDownload={() => {
          //             handleChangeDownload();
          //             setPurchaseDownldData(purchaseData);
          //           }}
          //           poType={poDataType}
          //         />
          //       </SearchPageCard>
          //     </div>
          //   );
          // })
          <>
            <TableContainer>
              <input
                type="text"
                placeholder="Search PO Number"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  height: "40px",
                  fontStyle: "normal",
                  outline: "none",
                  width: "20%",
                  borderRadius: "26px",
                  padding: "7px 16px",
                  border: "1px solid #ddd",
                  background: "white",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                      PO Number
                    </TableCell>
                    <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                      Vendor
                    </TableCell>
                    <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                      Date
                    </TableCell>
                    <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                      Status
                    </TableCell>
                    <TableCell style={{ color: "rgb(1, 150, 136)" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData && filteredData.length > 0 ? (
                    filteredData
                      .slice(
                        pages * rowsPerPages,
                        pages * rowsPerPages + rowsPerPages
                      )
                      .map((purchaseData, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {purchaseData.purchase_order_serial_number}
                          </TableCell>
                          <TableCell>{purchaseData.vendor?.name}</TableCell>
                          <TableCell>
                            {purchaseData.created_on.slice(0, 10)}
                          </TableCell>
                          <TableCell>
                            <p className="">
                              <div className="dropdown">
                                <Chip
                                  label={purchaseData.purchase_order_status}
                                  type={"active"}
                                />
                                <span className="dropdown-content">
                                  {user.loginType !== "client" &&
                                    orderStatus.map((item) => {
                                      if (
                                        purchaseData.purchase_order_status ===
                                          "Draft" &&
                                        item.value === "Revoke"
                                      ) {
                                        return null;
                                      }

                                      return (
                                        <a
                                          key={item.key}
                                          value={item.value}
                                          onClick={(e) =>
                                            handleChangeStatus(
                                              e,
                                              purchaseData.id
                                            )
                                          }
                                          className="m-0 py-1"
                                        >
                                          {item.label}
                                        </a>
                                      );
                                    })}
                                </span>
                              </div>
                            </p>
                          </TableCell>
                          <TableCell>
                            {user.loginType != "client" && (
                              <Tooltip title="Invoice">
                                <Link
                                  to={{
                                    pathname: `/invoice/${isEncoded(
                                      purchaseData.id
                                    )}`,
                                    state: {
                                      pState: purchaseData,
                                      proId: proId,
                                      budget_id: budget_id,
                                      rfqId: rfqId,
                                    },
                                  }}
                                >
                                  <IconButton>
                                    <InventoryOutlined
                                      checked={purchaseData.id}
                                      sx={{ color: colors.primary }}
                                    />
                                  </IconButton>
                                </Link>
                              </Tooltip>
                            )}

                            <IconButton>
                              <Tooltip title="View">
                                <RemoveRedEyeIcon
                                  checked={purchaseData.id}
                                  onClick={() => {
                                    handleView(purchaseData.id);
                                  }}
                                  sx={{ color: colors.primary }}
                                />
                              </Tooltip>
                            </IconButton>
                            {user.loginType != "client" && (
                              <IconButton>
                                <Tooltip title="Upload">
                                  <FileUploadIcon
                                    style={{
                                      fontSize: "23px",
                                      margin: "4px 5px ",
                                      cursor: "pointer",
                                      color: colors.primary,
                                    }}
                                    onClick={() => {
                                      handlecsvUploadItem("");
                                    }}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                            <IconButton>
                              <Tooltip title="Revise">
                                <PreviewIcon
                                  checked={purchaseData.id}
                                  onClick={() => {
                                    handleReviseStatus(purchaseData.id);
                                  }}
                                  sx={{ color: colors.primary }}
                                />
                              </Tooltip>
                            </IconButton>
                            {isUpdate && (
                              <IconButton
                                onClick={() => {
                                  openAddModal();
                                  handleSelectBtn("edit");
                                  setCurrentUserType(purchaseData);
                                }}
                              >
                                <Tooltip title="Edit">
                                  <EditIcon className={"iconSwitch"} />
                                </Tooltip>
                              </IconButton>
                            )}
                            {isStatus && (
                              <IconButton>
                                <Tooltip title="Delete">
                                  <DeleteIcon
                                    checked={purchaseData.id}
                                    onClick={() => {
                                      handleChangeActiveStatus(purchaseData.id);
                                    }}
                                    sx={{ color: colors.danger }}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                            <IconButton>
                              <Tooltip title="Download">
                                <DownloadIcon
                                  style={{
                                    cursor: "pointer",
                                    color: colors.primary,
                                  }}
                                  onClick={() => {
                                    handleChangeDownload();
                                    setPurchaseDownldData(purchaseData);
                                  }}
                                />
                                {/* <BlobProvider document={<PurchaseOrderReport poData={purchaseData}/>}>
        {({ url }) => (
        <a href={url} target="_blank">
         
         </a>
          )}
          </BlobProvider> */}
                              </Tooltip>
                            </IconButton>
                            {isUpdate &&
                              user.loginType != "client" &&
                              purchaseData.PurchaseOrderApprovals.length ==
                                0 && (
                                <IconButton>
                                  <Tooltip title="Submit to Client">
                                    <ForwardToInboxIcon
                                      style={{
                                        cursor: "pointer",
                                        color: colors.primary,
                                      }}
                                      onClick={() => {
                                        // setAlertSubmission(true);
                                        setPurchaseId(purchaseData.id);
                                        handleSelectBtn("Submit To Client");
                                        setIsOpen(true);
                                        getProjectSpecificApprovers();
                                      }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                            {user.loginType == "client" &&
                              showApproveRejectStatus(
                                purchaseData.PurchaseOrderApprovals
                              ) &&
                              isActionTaken(
                                purchaseData.PurchaseOrderApprovals
                              ) && (
                                <IconButton>
                                  <Tooltip title="Approve">
                                    <CheckCircleIcon
                                      style={{
                                        cursor: "pointer",
                                        color: colors.primary,
                                      }}
                                      onClick={() => {
                                        setAlertClientSubmission(true);
                                        setApprove(true);
                                        setReject(false);
                                        setPurchaseId(purchaseData.id);
                                      }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                            {user.loginType == "client" &&
                              showApproveRejectStatus(
                                purchaseData.PurchaseOrderApprovals
                              ) &&
                              isActionTaken(
                                purchaseData.PurchaseOrderApprovals
                              ) && (
                                <IconButton>
                                  <Tooltip title="Reject">
                                    <CancelIcon
                                      style={{
                                        cursor: "pointer",
                                        color: colors.primary,
                                      }}
                                      onClick={() => {
                                        setAlertClientSubmission(true);
                                        setApprove(false);
                                        setReject(true);
                                        setPurchaseId(purchaseData.id);
                                      }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell
                        height={100}
                        colSpan={5}
                        style={{ textAlign: "center" }}
                      >
                        No data found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPages}
              page={pages}
              onPageChange={handleChangePages}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <>
            {isLoader ? (
              <LoaderComponent />
            ) : (
              <NodataFound
                onClick={() => {
                  openAddModal();
                  handleSelectBtn("add");
                }}
              />
            )}
          </>
        )}
      </Card>
      {/* <div className="d-flex justify-content-end mb-3">
        <Stack spacing={3}>
          <Pagination
            count={Math.ceil(totalData / 5)}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div> */}
    </div>
  );
};

export default SearchPurchaseOrdersPage;
